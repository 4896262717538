import { Button,  H3 } from "@blueprintjs/core";
import * as React from "react";
import '../../assets/css/points.css'


class Form extends React.Component<any,any> {
  public state={
    readonly:true
  }

  public S3IdUpdateFun=()=>{

    if(this.state.readonly){
      this.setState({readonly:false});
    }else{
      this.setState({readonly:true});
    }
  }
  
  public s3IdsUpdate=(v:any)=>{
    const busiensses = this.props.data.business.filter((x:any)=>{
      try {
  
      if(x.business_uid===this.props.data.selectedBusinessId){
        const xv = v.split(',')
        x.s3_ids=xv      
      }    
    } catch (error) {
        console.log(error);
        
    }
      return x
  
          });
        this.props.setState({business:busiensses})
  }
  

public render(){
  const {nOT,mas,rV,type,ccp}=this.props.data.Qualifying[0];
  const s3ids  = this.props.data.business.filter((x:any)=>{
return (x.business_uid===this.props.data.selectedBusinessId)
  });


  
let s3:string = '';    
if(s3ids[0]!==undefined && s3ids[0].s3_ids!==undefined && s3ids[0].s3_ids!==''){
try {
s3= s3ids[0].s3_ids.join();
} catch (error) {
  console.log(error);
  
}
}

    return(<div className="PointForm" >

                <H3 style={{textAlign:'center'}}>Point System Configration</H3>
       {this.props.data.selectedBusinessId!=='' ?   <div style={{display:'grid',marginTop:'0px'}}>

       <label className="label2">Business Name <span className="red">*</span></label>  
          <input type="text" readOnly={true} value={this.props.data.businessname}/>
    
          <label className="label2">Business Description <span className="red">*</span></label>  
          <textarea  onChange={(e)=>this.props.setState({description:e.target.value})}  value={this.props.data.description!=null?this.props.data.description:''}></textarea>

                <label className="label">Reward Tiers <span className="red">*</span></label>
                <select  className="businessselect" onChange={(e)=>this.props.setState({Tiers:e.target.value})}>
                  <option  selected={this.props.data.Tiers==='Qualifying' ? true : false} value={'Qualifying'}>Qualifying Purchases</option> 
                  <option   selected={this.props.data.Tiers==='Tiered' ? true : false}  value={'Tiered'}>Tiered</option>
                </select> 

      <label className="label">Disable<span className="red">*</span></label>
                <select  className="businessselect" onChange={(e)=>this.props.setState({isDisabled:e.target.value})}>
                <option  selected={this.props.data.isDisabled===1 || this.props.data.isDisabled==='1'  ? true : false} value={1}>Yes</option> 
                <option   selected={this.props.data.isDisabled===0 || this.props.data.isDisabled==='0' || this.props.data.isDisabled===null ? true : false}  value={0}>No</option>
    
      </select> 
            
          {this.props.data.Tiers==='Tiered' ?
          <div style={{display:'grid'}}>
          <label className="label2">Point Value Per Spend <span className="red">*</span></label>  
          <input type="number" onChange={(e:any)=>this.props.setState({pointsvalue:e.target.value})} value={this.props.data.pointsvalue}/>
    
          {this.props.data.tierDivs.map((x:any,i:any)=> <div key={i} className="mapDivs">
    
                  <label className="label2">Point Value to Reach Reward <span className="red">*</span></label>  
                  <input type="number" onChange={(e:any)=>this.props.Updatevalues(x,i,'pointVTRR',e.target.value)}  value={x.pointVTRR}/>
                  <label className="label2">Reward Value <span className="red">*</span></label>  <span>
                  <input type="number" onChange={(e:any)=>this.props.Updatevalues(x,i,'rV',e.target.value)}   value={x.rV}/>
                  <select  className="businessselect" onChange={(e:any)=>this.props.Updatevalues(x,i,'type',e.target.value)}>  
                  <option selected={x.type==='$' ? true : false}  value={'$'}>$</option>
                  <option  selected={x.type==='%' ? true : false}  value={'%'}>%</option>
                  </select>
                {x.type==='%' ? <span style={{display:'grid'}}> <label className="label2">Coupon Cap Amount</label>
                  <input type="number" onChange={(e:any)=>this.props.Updatevalues(x,i,'cca',e.target.value)}   value={x.cca}/></span> : '' }
                
    
              {i!==0?    <Button style={{float:'right'}} intent="danger" onClick={()=>this.props.removediv(x)}>Remove</Button>:''}
                  </span>
    
            </div>)}
          <Button  style={{float:'left',width:'30%'}} intent="success" onClick={()=>this.props.addnew()}>Add new</Button>
    
                      <label className="label">Point Earned Time Delay</label>
                      <input type="number"  className="businessselect" onChange={(e)=>this.props.setState({earnetdelay:e.target.value})} value={this.props.data.earnetdelay}/>   
    
          <div style={{padding:'5px'}}>
          <div   style={{display:'grid'}} > <label className="label">Coupon Expiry Days</label> <input type="number" onChange={(e)=>this.props.setState({days:e.target.value})} value={this.props.data.days}/> </div> 
    
                </div>
          </div>
    // Qualifying Purchases
          : 
          <div  style={{display:'grid',border:'1px solid #ebeaea',padding:'8px'}}>
    
          <label className="label2">Number Of Trasaction <span className="red">*</span></label>  
          <input type="number"  onChange={(e:any)=>this.props.Updatevaluesquantity(0,'nOT',e.target.value)}   value={nOT}/>
    
          <label className="label2" >Minimum Amount Spend <span className="red">*</span></label>  
          <input type="number" onChange={(e:any)=>this.props.Updatevaluesquantity(0,'mas',e.target.value)}  value={mas}/>
    
          <label className="label2" >Reward Value <span className="red">*</span></label>  <span>
          <input type="number" onChange={(e:any)=>this.props.Updatevaluesquantity(0,'rV',e.target.value)}  value={rV}/>
          <select  className="businessselect" onChange={(e:any)=>this.props.Updatevaluesquantity(0,'type',e.target.value)}>  
          <option  selected={type==='$' ? true : false}   value={'$'}>$</option>
          <option  selected={type==='%' ? true : false}   value={'%'}>%</option>
          </select>
          </span>
    
          <label className="label2">Coupon Expiry Days</label>  
          <input type="number"  onChange={(e:any)=>this.props.setState({expirydays:e.target.value})}   value={this.props.data.expirydays}/>
    
    
          {type==='%' ? <label className="label2">Coupon Cap Amount</label>        :''}
          {type==='%' ?  <input type="number" onChange={(e:any)=>this.props.Updatevaluesquantity(0,'ccp',e.target.value)}  value={ccp}/>      :''}
    
          </div>
          }
    { this.props.data.order!==2 ?<div  style={{display:'grid',border:'1px solid #ebeaea',padding:'8px'}}>
  <label className="label2">S3Ids</label>
  <div  style={{display:'flex'}}>
  <input readOnly={this.state.readonly} style={{width:'100%'}} onChange={(e)=>this.s3IdsUpdate(e.target.value)} value={s3!=='' ? s3 : s3} />
  <Button small={true} className="group-btn" intent="primary" onClick={()=>this.S3IdUpdateFun()}>{this.state.readonly ? 'Edit' : 'Update'}</Button>
</div>
</div>:''}


{this.props.data.apiKey!=='' && this.props.data.order!==2 ?<div  style={{display:'grid',border:'1px solid #ebeaea',padding:'8px'}}>
  <label className="label2">{this.props.data.generateKey!=='' ? 'New Api Key': 'Api Key'}</label>
<input onChange={(e)=>this.props.setState({generateKey:e.target.value})} value={this.props.data.generateKey!=='' ? this.props.data.generateKey : this.props.data.apiKey}/>
</div>:''}
          <div >
          <p style={{color:'red',padding:'5px'}}>{this.props.data.msg}</p>
          <Button className="group-btn"  intent="danger" onClick={()=>this.props.clearCET1CET2(1)} >Cancel</Button> 
          <Button className="group-btn"  disabled={this.props.data.loading} onClick={this.props.handleform} intent="primary" >Save</Button>
  {this.props.data.order!==2 ? <Button className="group-btn"  disabled={this.props.data.loading} onClick={this.props.updateKey} intent="primary" >Update&nbsp;Api&nbsp;Key</Button> :''}  &nbsp;
    
    
     </div>
    </div> :
    <div className="rightblockDefaultImg">
      <img alt="img" src={require("../../assets/images/choice-icon-14.jpg")} title="Select Any Business "/>
    </div>
    }
            </div>)
}




}


export default Form;