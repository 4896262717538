import { Button, ButtonGroup, H1, H2, H3, H4, H5, InputGroup, Switch, TagInput, Tooltip } from "@blueprintjs/core";
import {  collection, doc, getDoc, query, updateDoc } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState } from "react";
import {  firebase, urls } from "../global/firebase";
import { InputMask } from '@react-input/mask';
import { CopyLink } from "./Copylink";
import { Editor } from '@tinymce/tinymce-react';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import QRCode from "qrcode.react";
import initApp from "../actions/init";
import { fetchingBool } from "../actions/businesses/PushBusinessesReducer";
import store from "../actions/store/store";

export const SiteForm=(data:any)=>{

    const defaultRoutes ={
        covidquestions:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        customertype:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        dateofbirth:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        email:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        language:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        name:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        optin:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        signature:{
            alwaysShow:false,
            enabled:false,
            optional:false,
            takeMugshot:false,
            waiverText:'',
            waiverTextEs:'',
        },
        supercard:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        thanks:{
            timeout:1000
        },
        vehicles:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        zip:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
    }
    const [CurrentSite ,SetCurrentSite] =useState([] as any)
    const [Disabled,setDisabled]=useState(false);
    const [bool,setbool]=useState(false);
    const [EnableKioskAdvertising,setEnableKioskAdvertising]=useState(false)
    // Variables
    const [accessDashboardSize,setaccessDashboardSize]=useState('22px');
    const [accessDashboardSizeM,setaccessDashboardSizeM]=useState('22px');
    const [accessDashboardText,setaccessDashboardText]=useState(String);
    const [accessDashboardTextSpanish,setaccessDashboardTextSpanish]=useState(String);
    const [accessDashboardType,setaccessDashboardType]=useState(String);
    const [feedtype,setfeedtype]=useState(String);
    const [finishPlayText,setfinishPlayText]=useState(String);
    const [finishPlay,setfinishPlay]=useState(false);
    const [allowOptOut,setallowOptOut]=useState(false);
    const [autoredirectCheckInFinish,setautoredirectCheckInFinish]=useState(false);
    const [businessId,setbusinessId]=useState(String);
    const [descSize,setdescSize]=useState('17px');
    const [descSizeM,setdescSizeM]=useState('17px');
    const [descText,setdescText]=useState(String);
    const [descTextSecondline,setdescTextSecondline]=useState(String);
    const [descTextSpanish,setdescTextSpanish]=useState(String);
    const [descTextSpanishSecondline,setdescTextSpanishSecondline]=useState(String);
    const [descType,setdescType]=useState(String);
    const [disableKeyboard,setdisableKeyboard]=useState(false);
    const [enableExpanolBtn,setenableExpanolBtn]=useState(false);
    const [enableItalic,setenableItalic]=useState(false);
    const [enableRowInTxtMsg,setenableRowInTxtMsg]=useState(false);
    const [enableVipClub,setenableVipClub]=useState(false);
    const [englishKeyword,setenglishKeyword]=useState(String);
    const [englishLocation,setenglishLocation]=useState(String);
    const [englishVipClubCheckboxText,setenglishVipClubCheckboxText]=useState(String);
    const [englishVipClubHoverText,setenglishVipClubHoverText]=useState(String);
    const [guid,setguid]=useState(String);
    const [heightForDesktop,setheightForDesktop]=useState(String);
    const [heightForMobile,setheightForMobile]=useState(String);
    const [helpMenu,sethelpMenu]=useState(String);
    const [helpMenus,sethelpMenus]=useState(String);
    const [homeBtnEnable,sethomeBtnEnable]=useState(false);
    const [homeBtnUrl,sethomeBtnUrl]=useState(String);
    const [homeButtonBgColor,sethomeButtonBgColor]=useState(String);
    const [homeButtonColor,sethomeButtonColor]=useState(String);
    const [homeButtonText,sethomeButtonText]=useState(String);
    const [homeButtonTextSpanish,sethomeButtonTextSpanish]=useState(String);
    const [howitworksBgColor,sethowitworksBgColor]=useState(String);
    const [howitworksColor,sethowitworksColor]=useState(String);
    const [howitworksImg,sethowitworksImg]=useState(String);
    const [howitworksText,sethowitworksText]=useState(String);
    const [isArchive,setisArchive]=useState(String);
    const [jiSignUpUiBackgroundColor,setjiSignUpUiBackgroundColor]=useState(String);
    const [jiSignUpUiFontColor,setjiSignUpUiFontColor]=useState(String);
    const [keyword,setkeyword]=useState(String);
    const [keywordEs,setkeywordEs]=useState(String);
    const [kioskName,setkioskName]=useState(String);
    const [kioskNameEs,setkioskNameEs]=useState(String);
    const [kioskUiAdvBottom,setkioskUiAdvBottom]=useState(String);
    const [kioskUiAdvDelaySec,setkioskUiAdvDelaySec]=useState(String);
    const [kioskUiAdvHeight,setkioskUiAdvHeight]=useState(String);
    const [kioskUiAdvTop,setkioskUiAdvTop]=useState(String);
    const [kioskUiAdvWidth,setkioskUiAdvWidth]=useState(String);
    const [kioskUiAllInOneCheckIn,setkioskUiAllInOneCheckIn]=useState(false);
    const [kioskUiBackgroundColor,setkioskUiBackgroundColor]=useState(String);
    const [kioskUiButtonDisabledColor,setkioskUiButtonDisabledColor]=useState(String);
    const [kioskUiButtonErrorColor,setkioskUiButtonErrorColor]=useState(String);
    const [kioskUiButtonKeyColor,setkioskUiButtonKeyColor]=useState(String);
    const [kioskUiButtonPrimaryColor,setkioskUiButtonPrimaryColor]=useState('#2b3c8b');
    const [kioskUiButtonSuccessColor,setkioskUiButtonSuccessColor]=useState('#467f1e');
    const [kioskUiContinueBtnTextColor,setkioskUiContinueBtnTextColor]=useState(String);
    const [kioskUiHideAdvIn,setkioskUiHideAdvIn]=useState(false);
    const [kioskUiHideAdvOut,setkioskUiHideAdvOut]=useState(false);
    const [kioskUiHideCity,setkioskUiHideCity]=useState(false);
    const [kioskUiHideContBtn,setkioskUiHideContBtn]=useState(false);
    const [kioskUiHideDefaultWaiver,setkioskUiHideDefaultWaiver]=useState(false);
    const [kioskUiInAdvFile1,setkioskUiInAdvFile1]=useState(String);
    const [kioskUiInAdvFile10,setkioskUiInAdvFile10]=useState(String);
    const [kioskUiInAdvFile2,setkioskUiInAdvFile2]=useState(String);
    const [kioskUiInAdvFile3,setkioskUiInAdvFile3]=useState(String);
    const [kioskUiInAdvFile4,setkioskUiInAdvFile4]=useState(String);
    const [kioskUiInAdvFile5,setkioskUiInAdvFile5]=useState(String);
    const [kioskUiInAdvFile6,setkioskUiInAdvFile6]=useState(String);
    const [kioskUiInAdvFile7,setkioskUiInAdvFile7]=useState(String);
    const [kioskUiInAdvFile8,setkioskUiInAdvFile8]=useState(String);
    const [kioskUiInAdvFile9,setkioskUiInAdvFile9]=useState(String);
    const [kioskUiLogoBorderColor,setkioskUiLogoBorderColor]=useState(String);
    const [kioskUiLogoBorderEnable,setkioskUiLogoBorderEnable]=useState(Boolean);
    const [kioskUiLogoHeight,setkioskUiLogoHeight]=useState(String);
    const [kioskUiLogoWidth,setkioskUiLogoWidth]=useState(String);
    const [howitworksTexts,sethowitworksTexts]=useState(String);
    const [kioskUiOutAdvFile1,setkioskUiOutAdvFile1]=useState(String);
    const [kioskUiOutAdvFile10,setkioskUiOutAdvFile10]=useState(String);
    const [kioskUiOutAdvFile2,setkioskUiOutAdvFile2]=useState(String);
    const [kioskUiOutAdvFile3,setkioskUiOutAdvFile3]=useState(String);
    const [kioskUiOutAdvFile4,setkioskUiOutAdvFile4]=useState(String);
    const [kioskUiOutAdvFile5,setkioskUiOutAdvFile5]=useState(String);
    const [kioskUiOutAdvFile6,setkioskUiOutAdvFile6]=useState(String);
    const [kioskUiOutAdvFile7,setkioskUiOutAdvFile7]=useState(String);
    const [kioskUiOutAdvFile8,setkioskUiOutAdvFile8]=useState(String);
    const [kioskUiOutAdvFile9,setkioskUiOutAdvFile9]=useState(String);
    const [kioskUiShowSigSmiley,setkioskUiShowSigSmiley]=useState(false);
    const [kioskUiSigHideAdvIn,setkioskUiSigHideAdvIn]=useState(false);
    const [kioskUiSigHideAdvOut,setkioskUiSigHideAdvOut]=useState(false);
    const [kioskUiSigInAdvFile1,setkioskUiSigInAdvFile1]=useState(String);
    const [kioskUiSigInAdvFile10,setkioskUiSigInAdvFile10]=useState(String);
    const [kioskUiSigInAdvFile2,setkioskUiSigInAdvFile2]=useState(String);
    const [kioskUiSigInAdvFile3,setkioskUiSigInAdvFile3]=useState(String);
    const [kioskUiSigInAdvFile4,setkioskUiSigInAdvFile4]=useState(String);
    const [kioskUiSigInAdvFile5,setkioskUiSigInAdvFile5]=useState(String);
    const [kioskUiSigInAdvFile6,setkioskUiSigInAdvFile6]=useState(String);
    const [kioskUiSigInAdvFile7,setkioskUiSigInAdvFile7]=useState(String);
    const [kioskUiSigInAdvFile8,setkioskUiSigInAdvFile8]=useState(String);
    const [kioskUiSigInAdvFile9,setkioskUiSigInAdvFile9]=useState(String);
    const [kioskUiSigOutAdvFile1,setkioskUiSigOutAdvFile1]=useState(String);
    const [kioskUiSigOutAdvFile10,setkioskUiSigOutAdvFile10]=useState(String);
    const [kioskUiSigOutAdvFile2,setkioskUiSigOutAdvFile2]=useState(String);
    const [kioskUiSigOutAdvFile3,setkioskUiSigOutAdvFile3]=useState(String);
    const [kioskUiSigOutAdvFile4,setkioskUiSigOutAdvFile4]=useState(String);
    const [kioskUiSigOutAdvFile5,setkioskUiSigOutAdvFile5]=useState(String);
    const [kioskUiSigOutAdvFile6,setkioskUiSigOutAdvFile6]=useState(String);
    const [kioskUiSigOutAdvFile7,setkioskUiSigOutAdvFile7]=useState(String);
    const [kioskUiSigOutAdvFile8,setkioskUiSigOutAdvFile8]=useState(String);
    const [kioskUiSigOutAdvFile9,setkioskUiSigOutAdvFile9]=useState(String);
    const [kioskUiTabletView,setkioskUiTabletView]=useState(false);
    const [kioskUiTermTextColor,setkioskUiTermTextColor]=useState(String);
    const [kioskUiTermTextSize,setkioskUiTermTextSize]=useState('13px');
    const [kioskUiTextColor,setkioskUiTextColor]=useState(String);
    const [kioskUiTextEngEditor,setkioskUiTextEngEditor]=useState(String);
    const [kioskUiTextSpnEditor,setkioskUiTextSpnEditor]=useState(String);
    const [kioskUiTitleTextColor,setkioskUiTitleTextColor]=useState(String);
    const [kioskUiVehicleConfig,setkioskUiVehicleConfig]=useState(false);
    const [kioskUiWaiverScroll,setkioskUiWaiverScroll]=useState(false);
    const [kioskUiliability,setkioskUiliability]=useState(false);
    const [mobileValidation,setmobileValidation]=useState(false);
    const [noCheckIn,setnoCheckIn]=useState(false);
    const [path,setpath]=useState(String);
    const [retToPageSize,setretToPageSize]=useState('28px');
    const [retToPageSizeM,setretToPageSizeM]=useState('28px');
    const [retToPageText,setretToPageText]=useState(String);
    const [retToPageTextSpanish,setretToPageTextSpanish]=useState(String);
    const [retToPageType,setretToPageType]=useState(String);
    const [timedelay,settimedelay]=useState(3)
    const [routes,setroutes]=useState(defaultRoutes);
    const [s3Id,sets3Id]=useState(String);
    const [shortcode,setshortcode]=useState(String);
    const [showCoupons,setshowCoupons]=useState(false );
    const [showLoyaltyReward,setshowLoyaltyReward]=useState(false);
    const [siteId,setsiteId]=useState(String);
    const [siteName,setsiteName]=useState(String);
    const [smartMatching,setsmartMatching]=useState(false);
    const [spanishKeyword,setspanishKeyword]=useState(String);
    const [spanishLocation,setspanishLocation]=useState(String);
    const [spanishVipClubCheckboxText,setspanishVipClubCheckboxText]=useState(String);
    const [spanishVipClubHoverText,setspanishVipClubHoverText]=useState(String);
    const [timezone,settimezone]=useState(String);
    const [titleSize,settitleSize]=useState('24px');
    const [titleSizeM,settitleSizeM]=useState('24px');
    const [titleText,settitleText]=useState(String);
    const [titleTextSpanish,settitleTextSpanish]=useState(String);
    const [titleType,settitleType]=useState(String);

    const [tncSize,settncSize]=useState('19px');
    const [tncSizeM,settncSizeM]=useState('19px');
    const [tncText,settncText]=useState(String);
    const [tncTextSpanish,settncTextSpanish]=useState(String);
    const [tncType,settncType]=useState(String);

    const [urgId,seturgId]=useState(String);
    const [widthForDesktop,setwidthForDesktop]=useState(String);
    const [widthForMobile,setwidthForMobile]=useState(String);
    const [justInKeyword,setjustInKeyword]=useState(String);

    const [busienssNext,setbusienssNext]=useState([] as any);
    const [yardsmartfeed,setyardsmartfeed]=useState(false)
    const [yardsmartid,setyardsmartid]=useState('');
    const [Ivisiontoken,setIvisiontoken]=useState('');
    const [summeryreport,setsummeryreport]=useState(false);
    const [allkeywords,setallkeywords]=useState([]);
    
    useEffect(()=>{ 
     
        if(siteId!==data.payload.id || bool===true){
        Reset();
        getSite();
        }
         },[data,bool]);
              
         const getSite=async()=>{

            const collectionbusiness= collection(firebase, 'businesses')
            const docSnapbsienss:any = (await getDoc( doc(collectionbusiness, data.payload.businessId))).data(); 

            setbusienssNext(docSnapbsienss)
             setsiteId(data.payload.id)
             
             setbusinessId(data.payload.businessId)
             const collectionw= collection(firebase, 'businesses/'+data.payload.businessId+'/sites')
             const docRef = doc(collectionw, data.payload.id);
             const docSnap:any = (await getDoc(docRef)).data(); 
             
             Object.keys(docSnap)
                .forEach(function eachKey(key) { 
                    const val =docSnap[key]!==undefined && typeof docSnap[key] === 'string' ? docSnap[key].trim()  : docSnap[key];
                    handleSiteChangesAndUpdate(false,key,val)
                });                
             setroutes(docSnap.routes)      
             SetCurrentSite(docSnap)
             setDisabled(false)
             setbool(false)
         }

const Reset=()=>{
            setjustInKeyword('')
            setaccessDashboardText('')
            setaccessDashboardTextSpanish('')
            setaccessDashboardType('')
            setallowOptOut(false)
            setautoredirectCheckInFinish(false)
            setdescSize('17px')
            setdescSizeM('17px')
            setdescText('')
            setdescTextSecondline('')
            setdescTextSpanish('')
            setdescTextSpanishSecondline('')
            setdescType('')
            setdisableKeyboard(false)
            setenableExpanolBtn(false)
            setenableItalic(false)
            setenableRowInTxtMsg(false)
            setenableVipClub(false)
            setenglishKeyword('')
            setenglishLocation('')
            setenglishVipClubCheckboxText('')
            setenglishVipClubHoverText('')
            setguid('')
            setheightForDesktop('')
            setheightForMobile('')
            sethelpMenu('')
            sethelpMenus('')
            sethomeBtnEnable(false)
            sethomeBtnUrl('')
            sethomeButtonBgColor('')
            sethomeButtonColor('')
            sethomeButtonText('')
            sethomeButtonTextSpanish('')
            sethowitworksBgColor('')
            sethowitworksColor('')
            sethowitworksImg('')
            sethowitworksText('')
            sethowitworksTexts('')
            setisArchive('')
            setjiSignUpUiBackgroundColor('')
            setjiSignUpUiFontColor('')
            setkeyword('')
            setkeywordEs('')
            setkioskName('')
            setkioskNameEs('')
            setkioskUiAdvBottom('')
            setkioskUiAdvDelaySec('')
            setkioskUiAdvHeight('')
            setkioskUiAdvTop('')
            setkioskUiAdvWidth('')
            setkioskUiAllInOneCheckIn(false)
            setkioskUiBackgroundColor('')
            setkioskUiButtonDisabledColor('#888888')
            setkioskUiButtonErrorColor('#e23229')
            setkioskUiButtonKeyColor('#ffffff')
            setkioskUiButtonPrimaryColor('#2b3c8b')
            setkioskUiButtonSuccessColor('#467f1e')
            setkioskUiContinueBtnTextColor('#ffffff')
            setkioskUiHideAdvIn(false)
            setkioskUiHideAdvOut(false)
            setkioskUiHideCity(false)
            setkioskUiHideContBtn(false)
            setkioskUiHideDefaultWaiver(false)
            setkioskUiInAdvFile1('')
            setkioskUiInAdvFile10('')
            setkioskUiInAdvFile2('')
            setkioskUiInAdvFile3('')
            setkioskUiInAdvFile4('')
            setkioskUiInAdvFile5('')
            setkioskUiInAdvFile6('')
            setkioskUiInAdvFile7('')
            setkioskUiInAdvFile8('')
            setkioskUiInAdvFile9('')
            setkioskUiLogoBorderColor('')
            setkioskUiLogoBorderEnable(false)
            setkioskUiLogoHeight('')
            setkioskUiLogoWidth('')
            setkioskUiOutAdvFile1('')
            setkioskUiOutAdvFile10('')
            setkioskUiOutAdvFile2('')
            setkioskUiOutAdvFile3('')
            setkioskUiOutAdvFile4('')
            setkioskUiOutAdvFile5('')
            setkioskUiOutAdvFile6('')
            setkioskUiOutAdvFile7('')
            setkioskUiOutAdvFile8('')
            setkioskUiOutAdvFile9('')
            setkioskUiShowSigSmiley(false)
            setkioskUiSigHideAdvIn(false)
            setkioskUiSigHideAdvOut(false)
            setkioskUiSigInAdvFile1('')
            setkioskUiSigInAdvFile10('')
            setkioskUiSigInAdvFile2('')
            setkioskUiSigInAdvFile3('')
            setkioskUiSigInAdvFile4('')
            setkioskUiSigInAdvFile5('')
            setkioskUiSigInAdvFile6('')
            setkioskUiSigInAdvFile7('')
            setkioskUiSigInAdvFile8('')
            setkioskUiSigInAdvFile9('')
            setkioskUiSigOutAdvFile1('')
            setkioskUiSigOutAdvFile10('')
            setkioskUiSigOutAdvFile2('')
            setkioskUiSigOutAdvFile3('')
            setkioskUiSigOutAdvFile4('')
            setkioskUiSigOutAdvFile5('')
            setkioskUiSigOutAdvFile6('')
            setkioskUiSigOutAdvFile7('')
            setkioskUiSigOutAdvFile8('')
            setkioskUiSigOutAdvFile9('')
            setkioskUiTabletView(false)
            setkioskUiTermTextColor('#e8a530')
            setkioskUiTermTextSize('13px')
            setkioskUiTextColor('#fcad14')
            setkioskUiTextEngEditor('')
            setkioskUiTextSpnEditor('')
            setkioskUiTitleTextColor('#fcad14')
            setkioskUiVehicleConfig(false)
            setkioskUiWaiverScroll(false)
            setkioskUiliability(false)
            setmobileValidation(false)
            setnoCheckIn(false)
            setpath('')
            setretToPageSize('28px')
            setretToPageSizeM('28px')
            setretToPageText('')
            setretToPageTextSpanish('')
            setretToPageType('')
            setroutes(defaultRoutes)                  
            sets3Id('')
            setshortcode('')
            setshowCoupons(false)
            setshowLoyaltyReward(false)
            setsiteId('')
            setsiteName('')
            setsmartMatching(false)
            setspanishKeyword('')
            setspanishLocation('')
            setspanishVipClubCheckboxText('')
            setspanishVipClubHoverText('')                 
            settimezone('')               
            settitleSize('24px')
            settitleSizeM('24px')
            settitleText('')
            settitleTextSpanish('')
            settitleType('')
            settncSize('19px')
            settncSizeM('19px')
            settncText('')
            settncTextSpanish('')
            settncType('')
            seturgId('')
            setwidthForDesktop('')
            setwidthForMobile('')
            settimedelay(3)  
            setfinishPlayText('')
            setfinishPlay(false)
            setyardsmartfeed(false)
            setyardsmartid('')
            setIvisiontoken('')
            setsummeryreport(false)
            setallkeywords([])
                                                                                                                                                              
}


    const handleSiteChangesAndUpdate=async(bool:boolean,field:string,value:any)=>{
             setDisabled(true)            
            switch (field) {

                case 'yardsmartfeed':
                    setyardsmartfeed(value)
                    if(bool===true) updateField({yardsmartfeed:value})                    
                    break; 
                case 'Ivisiontoken':
                    setIvisiontoken(value)
                    if(bool===true) updateField({Ivisiontoken:value})                    
                    break; 
                case 'summeryreport':
                    setsummeryreport(value)
                    if(bool===true) updateField({summeryreport:value})                    
                    break; 
                case 'allkeywords':
                        setallkeywords(value)
                        if(bool===true) updateField({allkeywords:value})                    
                        break;
                    
                            
                case 'yardsmartid':
                    setyardsmartid(value)
                    if(bool===true) updateField({yardsmartid:value})                    
                    break; 

                case 'accessDashboardSize':
                    setaccessDashboardSize(value)
                    if(bool===true) updateField({accessDashboardSize:value})                    
                    break;
                case 'justInKeyword':
                        setjustInKeyword(value)
                        if(bool===true) updateField({justInKeyword:value})                    
                        break;                       
                case 'finishPlayText':
                    setfinishPlayText(value)
                    if(bool===true) updateField({finishPlayText:value})                    
                    break;  
                case 'finishPlay':
                    setfinishPlay(value)
                    if(bool===true) updateField({finishPlay:value})                    
                    break;  
                case 'accessDashboardSizeM':
                    setaccessDashboardSizeM(value)
                    if(bool===true) updateField({accessDashboardSizeM:value})  
                    break;   
                case 'accessDashboardText':
                    setaccessDashboardText(value)
                    if(bool===true) updateField({accessDashboardText:value})   
                    break;   
                case 'accessDashboardTextSpanish':
                    setaccessDashboardTextSpanish(value)
                    if(bool===true) updateField({accessDashboardTextSpanish:value})   
                    break;   
                case 'accessDashboardType':
                    setaccessDashboardType(value)
                    if(bool===true) updateField({accessDashboardType:value})   
                    break;               
                case 'allowOptOut':
                    setallowOptOut(value)
                    if(bool===true) updateField({allowOptOut:value})   
                    break;  
                case 'autoredirectCheckInFinish':
                    setautoredirectCheckInFinish(value)
                    if(bool===true) updateField({autoredirectCheckInFinish:value})   
                    break;                            
                case 'descSize':
                    if(value!==null && value!==''){  setdescSize(value) }
                 if(bool===true) updateField({descSize:value})   
                 break;                   
                case 'descSizeM':
                    if(value!==null && value!==''){  setdescSizeM(value) }
                 if(bool===true) updateField({descSizeM:value})   
                 break; 
                case 'descText':
                  setdescText(value)
                  if(bool===true) updateField({descText:value})   
                  break; 
                case 'descTextSecondline':
                  setdescTextSecondline(value)
                  if(bool===true) updateField({descTextSecondline:value})   
                  break; 
                case 'descTextSpanish':
                  setdescTextSpanish(value)
                  if(bool===true) updateField({descTextSpanish:value})   
                  break; 
                case 'descTextSpanishSecondline':
                  setdescTextSpanishSecondline(value)
                  if(bool===true) updateField({descTextSpanishSecondline:value})   
                  break; 
                case 'descType':
                  setdescType(value)
                  if(bool===true) updateField({descType:value})   
                  break; 
                case 'disableKeyboard':
                  setdisableKeyboard(value)
                  if(bool===true) updateField({disableKeyboard:value})   
                  break; 
                case 'enableExpanolBtn':
                  setenableExpanolBtn(value)
                  if(bool===true) updateField({enableExpanolBtn:value})   
                  break; 
                case 'enableItalic':
                  setenableItalic(value)
                  if(bool===true) updateField({enableItalic:value})   
                  break; 
                case 'enableRowInTxtMsg':
                  setenableRowInTxtMsg(value)
                  if(bool===true) updateField({enableRowInTxtMsg:value})   
                  break; 
                case 'enableVipClub':
                  setenableVipClub(value)
                  if(bool===true) updateField({enableVipClub:value})   
                  break; 
                case 'englishKeyword':
                  setenglishKeyword(value)
                  if(bool===true) updateField({englishKeyword:value})   
                  break; 
                case 'englishLocation':
                  setenglishLocation(value)
                  if(bool===true) updateField({englishLocation:value})   
                  break; 
                case 'englishVipClubCheckboxText':
                  setenglishVipClubCheckboxText(value)
                  if(bool===true) updateField({englishVipClubCheckboxText:value})   
                  break; 
                case 'englishVipClubHoverText':
                  setenglishVipClubHoverText(value)
                  if(bool===true) updateField({englishVipClubHoverText:value})   
                  break; 
                case 'guid':
                  setguid(value)
                  if(bool===true) updateField({guid:value})   
                  break; 
                case 'heightForDesktop':
                  setheightForDesktop(value)
                  if(bool===true) updateField({heightForDesktop:value})   
                  break; 
                case 'heightForMobile':
                  setheightForMobile(value)
                  if(bool===true) updateField({heightForMobile:value})   
                  break; 
                case 'helpMenu':
                  sethelpMenu(value)
                  if(bool===true) updateField({helpMenu:value})   
                  break; 
                case 'helpMenus':
                  sethelpMenus(value)
                  if(bool===true) updateField({helpMenus:value})   
                  break; 
                case 'homeBtnEnable':
                  sethomeBtnEnable(value)
                  if(bool===true) updateField({homeBtnEnable:value})   
                  break; 
                case 'homeBtnUrl':
                  sethomeBtnUrl(value)
                  if(bool===true) updateField({homeBtnUrl:value})   
                  break; 
                case 'homeButtonBgColor':
                  sethomeButtonBgColor(value)
                  if(bool===true) updateField({homeButtonBgColor:value})   
                  break; 
                case 'homeButtonColor':
                  sethomeButtonColor(value)
                  if(bool===true) updateField({homeButtonColor:value})   
                  break; 
                case 'homeButtonText':
                  sethomeButtonText(value)
                  if(bool===true) updateField({homeButtonText:value})   
                  break; 
                case 'homeButtonTextSpanish':
                  sethomeButtonTextSpanish(value)
                  if(bool===true) updateField({homeButtonTextSpanish:value})   
                  break; 
                case 'howitworksBgColor':
                  sethowitworksBgColor(value)
                  if(bool===true) updateField({howitworksBgColor:value})   
                  break; 
                case 'howitworksColor':
                    sethowitworksColor(value)
                    if(bool===true) updateField({howitworksColor:value})   
                    break; 
                case 'howitworksImg':
                    sethowitworksImg(value)
                    if(bool===true) updateField({howitworksImg:value})   
                    break; 
                case 'howitworksText':
                    sethowitworksText(value)
                    if(bool===true) updateField({howitworksText:value})   
                    break; 
                case 'howitworksTexts':
                    sethowitworksTexts(value)
                    if(bool===true) updateField({howitworksTexts:value})   
                    break;                                                                                      
                case 'isArchive':
                    setisArchive(value)
                    if(bool===true) updateField({isArchive:value})   
                    break; 
                case 'jiSignUpUiBackgroundColor':
                    setjiSignUpUiBackgroundColor(value)
                    if(bool===true) updateField({jiSignUpUiBackgroundColor:value})   
                    break; 
                case 'jiSignUpUiFontColor':
                    setjiSignUpUiFontColor(value)
                    if(bool===true) updateField({jiSignUpUiFontColor:value})   
                    break; 
                case 'keyword':
                    setkeyword(value)
                    if(bool===true) updateField({keyword:value})   
                    break; 
                case 'keywordEs':
                    setkeywordEs(value)
                    if(bool===true) updateField({keywordEs:value})   
                    break; 
                case 'kioskName':
                    setkioskName(value)
                    if(bool===true) updateField({kioskName:value})   
                    break; 
                case 'kioskNameEs':
                    setkioskNameEs(value)
                    if(bool===true) updateField({kioskNameEs:value})   
                    break; 
                case 'kioskUiAdvBottom':
                    setkioskUiAdvBottom(value)
                    if(bool===true) updateField({kioskUiAdvBottom:value})   
                    break; 
                case 'kioskUiAdvDelaySec':
                    setkioskUiAdvDelaySec(value)
                    if(bool===true) updateField({kioskUiAdvDelaySec:value})   
                    break; 
                case 'kioskUiAdvHeight':
                    setkioskUiAdvHeight(value)
                    if(bool===true) updateField({kioskUiAdvHeight:value})   
                    break; 
                case 'kioskUiAdvTop':
                    setkioskUiAdvTop(value)
                    if(bool===true) updateField({kioskUiAdvTop:value})   
                    break; 
                case 'kioskUiAdvWidth':
                    setkioskUiAdvWidth(value)
                    if(bool===true) updateField({kioskUiAdvWidth:value})   
                    break; 
                case 'kioskUiAllInOneCheckIn':
                    setkioskUiAllInOneCheckIn(value)
                    if(bool===true) updateField({kioskUiAllInOneCheckIn:value})   
                    break; 
                case 'kioskUiBackgroundColor':
                    setkioskUiBackgroundColor(value)
                    if(bool===true) updateField({kioskUiBackgroundColor:value})   
                    break; 
                case 'kioskUiButtonDisabledColor':
                    if( value!==null ){   setkioskUiButtonDisabledColor(value) }
                    if(bool===true) updateField({kioskUiButtonDisabledColor:value})   
                    break; 
                case 'kioskUiButtonErrorColor':
                    if( value!==null ){    setkioskUiButtonErrorColor(value) }
                    if(bool===true) updateField({kioskUiButtonErrorColor:value})   
                    break; 
                case 'kioskUiButtonKeyColor':
                    if( value!==null ){  setkioskUiButtonKeyColor(value) }
                    if(bool===true) updateField({kioskUiButtonKeyColor:value})   
                    break; 
                case 'kioskUiButtonPrimaryColor':
                  if( value!==null ){  setkioskUiButtonPrimaryColor(value) }
                    if(bool===true) updateField({kioskUiButtonPrimaryColor:value})   
                    break; 
                case 'kioskUiButtonSuccessColor':
                    if( value!==null ){   setkioskUiButtonSuccessColor(value) }
                    if(bool===true) updateField({kioskUiButtonSuccessColor:value})   
                    break; 
                case 'kioskUiContinueBtnTextColor':
                    if( value!==null ){   setkioskUiContinueBtnTextColor(value) }
                    if(bool===true) updateField({kioskUiContinueBtnTextColor:value})   
                    break; 
                case 'kioskUiHideAdvIn':
                    setkioskUiHideAdvIn(value)
                    if(bool===true) updateField({kioskUiHideAdvIn:value})   
                    break; 
                case 'kioskUiHideAdvOut':
                    setkioskUiHideAdvOut(value)
                    if(bool===true) updateField({kioskUiHideAdvOut:value})   
                    break; 
                case 'kioskUiHideCity':
                    setkioskUiHideCity(value)
                    if(bool===true) updateField({kioskUiHideCity:value})   
                    break; 
                case 'kioskUiHideContBtn':
                    setkioskUiHideContBtn(value)
                    if(bool===true) updateField({kioskUiHideContBtn:value})   
                    break; 
                case 'kioskUiHideDefaultWaiver':
                    setkioskUiHideDefaultWaiver(value)
                    if(bool===true) updateField({kioskUiHideDefaultWaiver:value})   
                    break; 
                case 'kioskUiInAdvFile1':
                    setkioskUiInAdvFile1(value)
                    if(bool===true) updateField({kioskUiInAdvFile1:value})   
                    break; 
                case 'kioskUiInAdvFile10':
                    setkioskUiInAdvFile10(value)
                    if(bool===true) updateField({kioskUiInAdvFile10:value})   
                    break; 
                case 'kioskUiInAdvFile2':
                    setkioskUiInAdvFile2(value)
                    if(bool===true) updateField({kioskUiInAdvFile2:value})   
                    break; 
                case 'kioskUiInAdvFile3':
                    setkioskUiInAdvFile3(value)
                    if(bool===true) updateField({kioskUiInAdvFile3:value})   
                    break; 
                case 'kioskUiInAdvFile4':
                    setkioskUiInAdvFile4(value)
                    if(bool===true) updateField({kioskUiInAdvFile4:value})   
                    break; 
                case 'kioskUiInAdvFile5':
                    setkioskUiInAdvFile5(value)
                    if(bool===true) updateField({kioskUiInAdvFile5:value})   
                    break; 
                case 'kioskUiInAdvFile6':
                    setkioskUiInAdvFile6(value)
                    if(bool===true) updateField({kioskUiInAdvFile6:value})   
                    break; 
                case 'kioskUiInAdvFile7':
                    setkioskUiInAdvFile7(value)
                    if(bool===true) updateField({kioskUiInAdvFile7:value})   
                    break; 
                case 'kioskUiInAdvFile8':
                    setkioskUiInAdvFile8(value)
                    if(bool===true) updateField({kioskUiInAdvFile8:value})   
                    break; 
                case 'kioskUiInAdvFile9':
                    setkioskUiInAdvFile9(value)
                    if(bool===true) updateField({kioskUiInAdvFile9:value})   
                    break; 
                case 'kioskUiLogoBorderColor':
                    setkioskUiLogoBorderColor(value)
                    if(bool===true) updateField({kioskUiLogoBorderColor:value})   
                    break; 
                case 'kioskUiLogoBorderEnable':
                    setkioskUiLogoBorderEnable(value)
                    if(bool===true) updateField({kioskUiLogoBorderEnable:value})   
                    break; 
                case 'kioskUiLogoHeight':
                    setkioskUiLogoHeight(value)
                    if(bool===true) updateField({kioskUiLogoHeight:value})   
                    break; 
                case 'kioskUiLogoWidth':
                    setkioskUiLogoWidth(value)
                    if(bool===true) updateField({kioskUiLogoWidth:value})   
                    break; 
                case 'kioskUiOutAdvFile1':
                    setkioskUiOutAdvFile1(value)
                    if(bool===true) updateField({kioskUiOutAdvFile1:value})   
                    break; 
                case 'kioskUiOutAdvFile10':
                    setkioskUiOutAdvFile10(value)
                    if(bool===true) updateField({kioskUiOutAdvFile10:value})   
                    break; 
                case 'kioskUiOutAdvFile2':
                    setkioskUiOutAdvFile2(value)
                    if(bool===true) updateField({kioskUiOutAdvFile2:value})   
                    break; 
                case 'kioskUiOutAdvFile3':
                    setkioskUiOutAdvFile3(value)
                    if(bool===true) updateField({kioskUiOutAdvFile3:value})   
                    break; 
                case 'kioskUiOutAdvFile4':
                    setkioskUiOutAdvFile4(value)
                    if(bool===true) updateField({kioskUiOutAdvFile4:value})   
                    break; 
                case 'kioskUiOutAdvFile5':
                    setkioskUiOutAdvFile5(value)
                    if(bool===true) updateField({kioskUiOutAdvFile5:value})   
                    break; 
                case 'kioskUiOutAdvFile6':
                    setkioskUiOutAdvFile6(value)
                    if(bool===true) updateField({kioskUiOutAdvFile6:value})   
                    break; 
                case 'kioskUiOutAdvFile7':
                    setkioskUiOutAdvFile7(value)
                    if(bool===true) updateField({kioskUiOutAdvFile7:value})   
                    break; 
                case 'kioskUiOutAdvFile8':
                    setkioskUiOutAdvFile8(value)
                    if(bool===true) updateField({kioskUiOutAdvFile8:value})   
                    break; 
                case 'kioskUiOutAdvFile9':
                    setkioskUiOutAdvFile9(value)
                    if(bool===true) updateField({kioskUiOutAdvFile9:value})   
                    break; 
                case 'kioskUiShowSigSmiley':
                    setkioskUiShowSigSmiley(value)
                    if(bool===true) updateField({kioskUiShowSigSmiley:value})   
                    break; 
                case 'kioskUiSigHideAdvIn':
                    setkioskUiSigHideAdvIn(value)
                    if(bool===true) updateField({kioskUiSigHideAdvIn:value})   
                    break; 
                case 'kioskUiSigHideAdvOut':
                    setkioskUiSigHideAdvOut(value)
                    if(bool===true) updateField({kioskUiSigHideAdvOut:value})   
                    break; 
                case 'kioskUiSigInAdvFile1':
                    setkioskUiSigInAdvFile1(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile1:value})   
                    break; 
                case 'kioskUiSigInAdvFile10':
                    setkioskUiSigInAdvFile10(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile10:value})   
                    break; 
                case 'kioskUiSigInAdvFile2':
                    setkioskUiSigInAdvFile2(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile2:value})   
                    break; 
                case 'kioskUiSigInAdvFile3':
                    setkioskUiSigInAdvFile3(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile3:value})   
                    break; 
                case 'kioskUiSigInAdvFile4':
                    setkioskUiSigInAdvFile4(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile4:value})   
                    break; 
                case 'kioskUiSigInAdvFile5':
                    setkioskUiSigInAdvFile5(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile5:value})   
                    break; 
                case 'kioskUiSigInAdvFile6':
                    setkioskUiSigInAdvFile6(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile6:value})   
                    break; 
                case 'kioskUiSigInAdvFile7':
                    setkioskUiSigInAdvFile7(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile7:value})   
                    break; 
                case 'kioskUiSigInAdvFile8':
                    setkioskUiSigInAdvFile8(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile8:value})   
                    break; 
                case 'kioskUiSigInAdvFile9':
                    setkioskUiSigInAdvFile9(value)
                    if(bool===true) updateField({kioskUiSigInAdvFile9:value})   
                    break; 
                case 'kioskUiSigOutAdvFile1':
                    setkioskUiSigOutAdvFile1(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile1:value})   
                    break; 
                case 'kioskUiSigOutAdvFile10':
                    setkioskUiSigOutAdvFile10(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile10:value})   
                    break; 
                case 'kioskUiSigOutAdvFile2':
                    setkioskUiSigOutAdvFile2(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile2:value})   
                    break; 
                case 'kioskUiSigOutAdvFile3':
                    setkioskUiSigOutAdvFile3(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile3:value})   
                    break; 
                case 'kioskUiSigOutAdvFile4':
                    setkioskUiSigOutAdvFile4(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile4:value})   
                    break; 
                case 'kioskUiSigOutAdvFile5':
                    setkioskUiSigOutAdvFile5(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile5:value})   
                    break; 
                case 'kioskUiSigOutAdvFile6':
                    setkioskUiSigOutAdvFile6(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile6:value})   
                    break; 
                case 'kioskUiSigOutAdvFile7':
                    setkioskUiSigOutAdvFile7(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile7:value})   
                    break; 
                case 'kioskUiSigOutAdvFile8':
                    setkioskUiSigOutAdvFile8(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile8:value})   
                    break; 
                case 'kioskUiSigOutAdvFile9':
                    setkioskUiSigOutAdvFile9(value)
                    if(bool===true) updateField({kioskUiSigOutAdvFile9:value})   
                    break; 
                case 'kioskUiTabletView':
                    setkioskUiTabletView(value)
                    if(bool===true) updateField({kioskUiTabletView:value})   
                    break; 
                case 'kioskUiTermTextColor':
                    setkioskUiTermTextColor(value)
                    if(bool===true) updateField({kioskUiTermTextColor:value})   
                    break; 
                case 'kioskUiTermTextSize':
                    if(value!==null && value!==''){  setkioskUiTermTextSize(value) }
                    if(bool===true) updateField({kioskUiTermTextSize:value})   
                    break; 
                case 'kioskUiTextColor':
                  if(value!==null){  setkioskUiTextColor(value) }
                    if(bool===true) updateField({kioskUiTextColor:value})   
                    break; 
                case 'kioskUiTextEngEditor':
                    setkioskUiTextEngEditor(value)
                    if(bool===true) updateField({kioskUiTextEngEditor:value})   
                    break; 
                case 'kioskUiTextSpnEditor':
                    setkioskUiTextSpnEditor(value)
                    if(bool===true) updateField({kioskUiTextSpnEditor:value})   
                    break; 
                case 'kioskUiTitleTextColor':
                  if(value!==null){  setkioskUiTitleTextColor(value) }
                    if(bool===true) updateField({kioskUiTitleTextColor:value})   
                    break; 
                case 'kioskUiVehicleConfig':
                    setkioskUiVehicleConfig(value)
                    if(bool===true) updateField({kioskUiVehicleConfig:value})   
                    break; 
                case 'kioskUiWaiverScroll':
                    setkioskUiWaiverScroll(value)
                    if(bool===true) updateField({kioskUiWaiverScroll:value})   
                    break; 
                case 'kioskUiliability':
                    setkioskUiliability(value)
                    if(bool===true) updateField({kioskUiliability:value})   
                    break; 
                case 'mobileValidation':
                    setmobileValidation(value)
                    if(bool===true) updateField({mobileValidation:value})   
                    break; 
                case 'noCheckIn':
                    setnoCheckIn(value)
                    if(bool===true) updateField({noCheckIn:value})   
                    break; 
                case 'path':
                    setpath(value)
                    if(bool===true) updateField({path:value})   
                    break; 
                case 'retToPageSize':
                  if(value!==null && value!==''){  setretToPageSize(value) }
                    if(bool===true) updateField({retToPageSize:value})   
                    break; 
                case 'retToPageSizeM':
                    if(value!==null && value!==''){ setretToPageSizeM(value) }
                    if(bool===true) updateField({retToPageSizeM:value})   
                    break; 
                case 'retToPageText':
                    setretToPageText(value)
                    if(bool===true) updateField({retToPageText:value})   
                    break; 
                case 'retToPageTextSpanish':
                    setretToPageTextSpanish(value)
                    if(bool===true) updateField({retToPageTextSpanish:value})   
                    break; 
                case 'retToPageType':
                    setretToPageType(value)
                    if(bool===true) updateField({retToPageType:value})   
                    break; 
                case 'routes':
                    setroutes(value)                  
                    if(bool===true) updateField({routes:value})   
                    break; 
                case 's3Id':
                    sets3Id(value)
                    if(bool===true) updateField({s3Id:value})   
                    break; 
                case 'shortcode':
                    setshortcode(value)
                    if(bool===true) updateField({shortcode:value})   
                    break; 
                case 'showCoupons':
                    setshowCoupons(value)
                    if(bool===true) updateField({showCoupons:value})   
                    break; 
                case 'showLoyaltyReward':
                    setshowLoyaltyReward(value)
                    if(bool===true) updateField({showLoyaltyReward:value})   
                    break; 
                case 'siteId':
                    setsiteId(value)
                    if(bool===true) updateField({siteId:value})   
                    break; 
                case 'siteName':
                    setsiteName(value)
                    if(bool===true) updateField({siteName:value})   
                    break; 
                case 'smartMatching':
                    setsmartMatching(value)
                    if(bool===true) updateField({smartMatching:value})   
                    break; 
                case 'spanishKeyword':
                    setspanishKeyword(value)
                    if(bool===true) updateField({spanishKeyword:value})   
                    break; 
                case 'spanishLocation':
                    setspanishLocation(value)
                    if(bool===true) updateField({spanishLocation:value})   
                    break; 
                case 'spanishVipClubCheckboxText':
                    setspanishVipClubCheckboxText(value)
                    if(bool===true) updateField({spanishVipClubCheckboxText:value})   
                    break; 
                case 'spanishVipClubHoverText':
                    setspanishVipClubHoverText(value)
                    if(bool===true) updateField({spanishVipClubHoverText:value})   
                    break; 
                case 'timezone':                   
                    settimezone(value)               
                    if(bool===true) updateField({timezone:value})   
                    break; 
                case 'titleSize':
                if(value!==null && value!==''){ settitleSize(value) }
                    if(bool===true) updateField({titleSize:value})   
                    break; 
                case 'titleSizeM':
                    if(value!==null && value!==''){   settitleSizeM(value) }
                    if(bool===true) updateField({titleSizeM:value})   
                    break; 
                case 'titleText':
                    settitleText(value)
                    if(bool===true) updateField({titleText:value})   
                    break; 
                case 'titleTextSpanish':
                    settitleTextSpanish(value)
                    if(bool===true) updateField({titleTextSpanish:value})   
                    break; 
                case 'titleType':
                    settitleType(value)
                    if(bool===true) updateField({titleType:value})   
                    break; 
                case 'tncSize':
                    if(value!==null && value!==''){  settncSize(value) }
                    if(bool===true) updateField({tncSize:value})   
                    break; 
                case 'tncSizeM':
                    if(value!==null && value!==''){     settncSizeM(value) }
                    if(bool===true) updateField({tncSizeM:value})   
                    break; 
                case 'tncText':
                    settncText(value)
                    if(bool===true) updateField({tncText:value})   
                    break; 
                case 'tncTextSpanish':
                    settncTextSpanish(value)
                    if(bool===true) updateField({tncTextSpanish:value})   
                    break; 
                case 'tncType':
                    settncType(value)
                    if(bool===true) updateField({tncType:value})   
                    break; 
                case 'urgId':
                    seturgId(value)
                    if(bool===true) updateField({urgId:value})   
                    break; 
                case 'widthForDesktop':
                    setwidthForDesktop(value)
                    if(bool===true) updateField({widthForDesktop:value})   
                    break; 
                case 'widthForMobile':
                    setwidthForMobile(value)
                    if(bool===true) updateField({widthForMobile:value})   
                    break; 
                case 'timedelay':
                    try {
                        settimedelay(parseInt(value,10))
                    } catch (error) {
                        console.log(error);                        
                        settimedelay(value)
                    }
                    
                    if(bool===true) updateField({timedelay:value})   
                    break; 
                case 'feedType':
                    setfeedtype(value)
                    if(bool===true) updateField({feedType:value})   
                    break; 
                                                                                                                                                                
            }
        setTimeout(() => {   
    if(bool){
        getSite()
    }              
}, 1200); 
    }    
    


    const Handlesetroutes=(bool:boolean,name:string,name2:string,value:any)=>{
      if(bool){  setDisabled(true)}
        switch (name) {
            case 'signature':                
                switch (name2) {
                    case 'always':
                        routes.signature.alwaysShow=value                        
                    break; 
                    case 'enabled':
                        routes.signature.enabled=value
                    break; 
                    case 'optional':
                        routes.signature.optional=value
                    break; 
                    case 'takeMugshot':
                        routes.signature.takeMugshot=value
                    break;                     
                    case 'waiverText':
                        routes.signature.waiverText=value
                    break; 
                    case 'waiverTextEs':
                        routes.signature.waiverTextEs=value
                    break; 
                }
            break;
            case 'dateofbirth':                
                    switch (name2) {
                        case 'always':
                            routes.dateofbirth.alwaysShow=value                        
                        break; 
                        case 'enabled':
                            routes.dateofbirth.enabled=value
                        break; 
                        case 'optional':
                            routes.dateofbirth.optional=value                
                        break; 
                    }
        break; 
        case 'email':                
                switch (name2) {
                    case 'always':
                        routes.email.alwaysShow=value                        
                    break; 
                    case 'enabled':
                        routes.email.enabled=value
                    break; 
                    case 'optional':
                        routes.email.optional=value                
                    break; 
                }
        break; 
        case 'language':                
                switch (name2) {
                case 'always':
                    routes.language.alwaysShow=value                        
                break; 
                case 'enabled':
                    routes.language.enabled=value
                break; 
                case 'optional':
                    routes.language.optional=value                
                break; 
                }
        break; 
        case 'name':                
                switch (name2) {
                case 'always':
                    routes.name.alwaysShow=value                        
                break; 
                case 'enabled':
                    routes.name.enabled=value
                break; 
                case 'optional':
                    routes.name.optional=value                
                break; 
                }
       break; 
       case 'vehicles':                
                switch (name2) {
                case 'always':
                    routes.vehicles.alwaysShow=value                        
                break; 
                case 'enabled':
                    routes.vehicles.enabled=value
                break; 
                case 'optional':
                    routes.vehicles.optional=value                
                break; 
                }
        break; 
        case 'zip':                
                switch (name2) {
                    case 'always':
                        routes.zip.alwaysShow=value                        
                    break; 
                    case 'enabled':
                    routes.zip.enabled=value
                    break; 
                    case 'optional':
                    routes.zip.optional=value                
                    break; 
                }
        break; 

        case 'customertype':                
        switch (name2) {
            case 'always':
                routes.customertype.alwaysShow=value                        
            break; 
            case 'enabled':
            routes.customertype.enabled=value
            break; 
            case 'optional':
            routes.customertype.optional=value                
            break; 
        }
        break; 
        case 'supercard':                
        switch (name2) {
            case 'always':
                routes.supercard.alwaysShow=value                        
            break; 
            case 'enabled':
            routes.supercard.enabled=value
            break; 
            case 'optional':
            routes.supercard.optional=value                
            break; 
        }
        break; 
        case 'covidquestions':                
        switch (name2) {
            case 'always':
                routes.covidquestions.alwaysShow=value                        
            break; 
            case 'enabled':
            routes.covidquestions.enabled=value
            break; 
            case 'optional':
            routes.covidquestions.optional=value                
            break; 
        }
        break; 


        
       }
  if(bool){
    handleSiteChangesAndUpdate(true,'routes',routes)  
  }     
    }


    const updateField=async(datax:any)=>{
        await updateDoc(doc(firebase, `businesses`,businessId,'sites',siteId), datax).then(async docRef => {
            console.log('successfully updated '+siteId);   
            setDisabled(false) 
            setbool(true);
        }).catch((e:any)=>{
            console.log(e); 
           setDisabled(false);
        });


    }


    const OpenKiosk=()=>{
    const Kioskurl =     urls.baseUrlKiosk+'/'+busienssNext.path+'/'+path
    window.open(Kioskurl,'blank');
    }

           // logo upload business//
    const FileUploader=(field:any,folder:any,filex:any)=>{
            setDisabled(true);
            const storage = getStorage();
            // Create the file metadata
            /** @type {any} */
            const metadata = {
              contentType: 'image/jpeg'
            };
            // Upload file and metadata to the object 'images/mountains.jpg'
            const file =filex[0];
            const storageRef = ref(storage, `${folder}/` + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
              (snapshot:any) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                }
              }, 
              (error:any) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                  case 'storage/canceled':
                    // User canceled the upload
                    break;        
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }
              }, 
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
                    handleSiteChangesAndUpdate(true,field,downloadURL)
                    console.log(downloadURL);
    
                });
              }
            );
    
        }

         const Help = (text:any)=>{
            // console.log(text);
            if(text.text!==undefined){
            return (
              <ButtonGroup>
          <Tooltip  content={text.text}  position="top">
          <Button  icon={'help'} small={true} />
                              </Tooltip>
                              </ButtonGroup>
          )
            }else{
              return(<span/>)
            }
           }
    
           const  aryIannaTimeZones = [
            ["Eastern","America/New_York","America/New_York"],
             [ "Central","America/Chicago","America/Chicago"],
             ["Mountain","America/Denver","America/Denver"],
              ["Pacific","America/Los_Angeles","America/Los_Angeles"],
              ["Alaska","America/Anchorage","America/Anchorage"],
             ["Hawaii","Pacific/Honolulu","America/Honolulu"],
             ["Eastern ","America/Toronto","Canada/Toronto"],
             ["Central ","America/Winnipeg","Canada/Winnipeg"],
             ["Mountain ","America/Edmonton","Canada/Edmonton"],
             ["Pacific ","America/Vancouver","Canada/Vancouver"],
             ["Atlantic","America/Halifax","Canada/Halifax"],
             ["Newfoundland","America/St_Johns","Canada/St_Johns"],
             
           
               ];

    return(
        <div className="container-fluid ">
           {Disabled? <div style={{textAlign:'center'}}>
        <img alt="img" src={require('../assets/loading1.gif')} style={{position:'fixed',zIndex:1,top:'28%'}} height={100} width={100}/>
        </div>:''}
        <H1>{CurrentSite.siteName}</H1>
        <ButtonGroup>
        <div>{CurrentSite.isArchive===undefined || CurrentSite.isArchive===0 || CurrentSite.isArchive==='0' ? 
         <Button intent="danger" onClick={()=>updateField({isArchive:1})}>Archive</Button>
         :
         <Button intent="success" onClick={()=>updateField({isArchive:0})}>Restore</Button>
         }</div>
        </ButtonGroup>
        <Button disabled={path===undefined || path===null || path===''} intent="primary" style={{
            position: 'absolute',
            top:'49px',
            right:' 0px',
       }} onClick={()=>OpenKiosk()}>Go To Kiosk</Button>
<div className="form-field">
              <H5>waiver text</H5>
              <Editor apiKey="1dyw7zltp7o9q4g0czp3w8gbntjxh4p9fqywalmw6r18wmri"
            data-property-of="signature"
            disabled={Disabled}
            onChange={(evt: any,edot: any)=> Handlesetroutes(false,'signature','waiverText',edot.getContent())}
            initialValue={routes.signature.waiverText}
            textareaName='waiverText'
            init={{
             height: 300,
             menubar: true,
             plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount', 
             'fullscreen'        ,
             'emoticons'  ,
             'image',
             'spellchecker',
             'table' 
             ],
            toolbar: 'insertfile undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | spellchecker|  bullist numlist outdent indent | ' +
            'removeformat | link | emoticons | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | fullscreen | help'    
            ,
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           }}      
           />
              <ButtonGroup>
                <Button
                  data-property-of="signature"
                  disabled={Disabled}
                  intent="primary"
                  name="waiverText"
                  onClick={()=>handleSiteChangesAndUpdate(true,'routes',routes) } >
                  change waiver
                </Button>
              </ButtonGroup>
        </div>  
        <div className="form-field">
              <H5>waiver text (spanish) (optional)</H5>
              <Editor apiKey="1dyw7zltp7o9q4g0czp3w8gbntjxh4p9fqywalmw6r18wmri"
            data-property-of="signature"
            disabled={Disabled}
            onChange={(evt: any,edot: any)=>Handlesetroutes(false,'signature','waiverTextEs',edot.getContent()) }
            initialValue={routes.signature.waiverTextEs}
            textareaName='waiverText'
            init={{
             height: 300,
             menubar: true,
             plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount', 
             'fullscreen'        ,
             'emoticons'  ,
             'image',
             'spellchecker',
             'table' 
             ],
            toolbar: 'insertfile undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | spellchecker|  bullist numlist outdent indent | ' +
            'removeformat | link | emoticons | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | fullscreen | help'    
            ,
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           }}      
           />
              <ButtonGroup>
                <Button
                  data-property-of="signature"
                  disabled={Disabled}
                  intent="primary"
                  name="waiverText"
                  onClick={()=>handleSiteChangesAndUpdate(true,'routes',routes) } >
                  change waiver
                </Button>
              </ButtonGroup>
        </div>

         <div className="row-full-width">
            <div className="wi-50">
            <div className="form-field">
                <H3>Option</H3>
                <div className="labelzxc"><Switch disabled={Disabled} label={'Show loyalty reward' }  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'showLoyaltyReward',e.target.checked)} checked={showLoyaltyReward} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch disabled={Disabled} label={'Auto redirect when checkIn finish'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'autoredirectCheckInFinish',e.target.checked)} checked={autoredirectCheckInFinish} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch disabled={Disabled} label={'Phone number validation'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'mobileValidation',e.target.checked)} checked={mobileValidation} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch disabled={Disabled} label={'Prevent opt-in'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'noCheckIn',e.target.checked)} checked={noCheckIn} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch disabled={Disabled} label={'Allow opt-out'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'allowOptOut',e.target.checked)} checked={allowOptOut} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch disabled={Disabled} label={'Finish Check-In Sound (check-In history page)'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'finishPlay',e.target.checked)} checked={finishPlay} innerLabelChecked="on" innerLabel="off" /></div>

                <div className="labelzxc"><Switch disabled={Disabled} label={'Yard Smart Feed'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'yardsmartfeed',e.target.checked)} checked={yardsmartfeed} innerLabelChecked="on" innerLabel="off" /></div>

            </div>
            </div>
            <div className="wi-50">
                <div className="form-field">
                    <H3>TimeZone</H3>
                    <select className="formcontrol" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'timezone',e.target.value)} >
                            {aryIannaTimeZones.map((v:any,index:any)=>(<option key={index} selected={timezone===v[1]} value={v[1]} >{v[2]} ({v[0]}) </option>))}
                    </select>
                </div>      

            {/* {finishPlay?    <div className="form-field">
                    <H5>Finish Check-In Sound Text Message: <Help text={'Finish check-in sound text when checkin finish.'}/></H5>
                    <InputGroup
                        onChange={(e:any)=>setfinishPlayText(e.target.value)} 
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter message"
                        readOnly={false}
                        value={finishPlayText || ""}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'finishPlayText',finishPlayText)}
                        >change</Button>}
                    />
            </div>:''} */}


            </div>
         </div>
         {!autoredirectCheckInFinish ?  <div className="form-field">
                <H3>Thanks Page Time Delay </H3>
                    <select className="formcontrol"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'timedelay',e.target.value)} >
                            <option selected={timedelay===1} value={1} >1 second</option>                    
                            <option  selected={timedelay===2} value={2}>2 second</option>
                            <option  selected={timedelay===3} value={3}>3 second</option>
                            <option  selected={timedelay===4} value={4} >4 second </option>
                            <option  selected={timedelay===5} value={5}>5 second</option>
                            <option  selected={timedelay===6} value={6}>6 second</option>
                            <option  selected={timedelay===7} value={7}>7 second</option>
                            <option  selected={timedelay===8} value={8}>8 second</option>
                            <option  selected={timedelay===9} value={9} >9 second</option>
                            <option  selected={timedelay===10} value={10}>10 second</option>
                    </select>
                </div>:''}
  

                <div className="form-field">
                <H3>CheckIn+SMS Summary Report </H3>
                <div className="labelzxc"><Switch disabled={Disabled} label={'CheckIn+SMS Summary Report'}  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'summeryreport',e.target.checked)} checked={summeryreport} innerLabelChecked="on" innerLabel="off" /></div>

                </div>




        <div className="row-full-width">
        <div className="wi-50">
            <div className="form-field">
                    <H5>Site iVision GUID: <Help text={'guid'}/></H5>  
                    <div className="bp5-input-group">
                        <InputMask className="bp5-input font-20" mask="______________________________________" replacement={"_"} value={guid ? guid: ''} showMask separate  disabled={Disabled}
                        onChange={(e:any)=>setguid(e.target.value)} 
                        />   
                        <span className="bp5-input-action">
                            <button type="button" className="bp5-button bp5-small bp5-intent-primary"
                            onClick={(e:any)=>handleSiteChangesAndUpdate(true,'guid',guid)} 
                            >
                                <span className="bp5-button-text">change</span>
                            </button>
                        </span>
                    </div>                     
            </div>
            <div className="form-field">
                    <H5>ivision Message code: <Help text={'ivision code'}/></H5>
                    <InputGroup
                        onChange={(e:any)=>setshortcode(e.target.value)} 
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter ivision code"
                        readOnly={false}
                        value={shortcode || ""}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'shortcode',shortcode)}
                        >change</Button>}
                    />
            </div>

    {yardsmartfeed?        <div className="form-field">
                    <H5>Yard Smart Id: <Help text={'Yard Smart Id'}/></H5>
                    <InputGroup
                        onChange={(e:any)=>setyardsmartid(e.target.value)} 
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter yard smart id"
                        readOnly={false}
                        value={yardsmartid || ""}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'yardsmartid',yardsmartid)}
                        >change</Button>}
                    />
            </div>:''}


            <div className="form-field">
                    <H5>Just In Keyword Campaign Name: <Help text={'Just In Keyword Campaign Name'}/></H5>
                    <InputGroup
                        onChange={(e:any)=>setjustInKeyword(e.target.value)} 
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter just-In keyword "
                        readOnly={false}
                        value={justInKeyword || ""}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'justInKeyword',justInKeyword)}
                        >change</Button>}
                    />
            </div>

        </div>
        <div className="wi-50">
            <div className="form-field">
                    <H5>English Kiosk Name: <Help text={'English Kiosk Name'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        onChange={(e:any)=>setkioskName(e.target.value)}
                        large={false}
                        placeholder="Enter kioskName"
                        readOnly={false}
                        value={kioskName || ""}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskName',kioskName)}
                        >change</Button>}
                    />
            </div>
            <div className="form-field">
                    <H5>Spanish Kiosk Name: <Help text={'Spanish Kiosk Name'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        onChange={(e:any)=>setkioskNameEs(e.target.value)}
                        value={kioskNameEs || ""}
                        placeholder="Enter kioskNameEs"
                        readOnly={false}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskNameEs',kioskNameEs)}
                        >change</Button>}
                    />
            </div>



        {summeryreport==true?    <div className="form-field">
                    <H5>Sent sms count token: <Help text={'Business Ivision api token for sent sms count'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        onChange={(e:any)=>setIvisiontoken(e.target.value)}
                        value={Ivisiontoken || ""}
                        placeholder="Enter or Paste token"
                        readOnly={false}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'Ivisiontoken',Ivisiontoken)}
                        >change</Button>}
                    />
            </div>:''}

            
            {summeryreport==true?    <div className="form-field">
                    <H5> Site all keywords: <Help text={'All site keywords'}/></H5>
                    <TagInput
               disabled={Disabled}
               leftIcon={'inbox'}
                placeholder="site keywords"
                large={false}
                values={allkeywords}
                onChange={(e:any)=>{handleSiteChangesAndUpdate(true,'allkeywords',e); return true}}
            />
                
            </div>:''}
            

        </div>
        </div>        

        <div className="form-field">
                <H5>siteName: <Help text={'siteName'}/></H5>
                <InputGroup
                    disabled={Disabled}
                    onChange={(e:any)=>setsiteName(e.target.value)}
                    large={false}
                    placeholder="Enter siteName"
                    readOnly={false}
                    value={siteName}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'siteName',siteName)}
                    >change</Button>}
                />
        </div>


{/* kiosk ui */}

<div className="form-field">
            <H3>Kiosk UI <Help text={'Kiosk ui'}/></H3>
        </div>

        <div className="row-full-width">
    <div className="w-33">
        <div className="form-field">
                <H5>Background Color: <Help text={'Background Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Background Color"
                    readOnly={false}
                    value={kioskUiBackgroundColor || ""}
                    onChange={(e)=>setkioskUiBackgroundColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  onChange={(e)=>setkioskUiBackgroundColor(e.target.value)} value={kioskUiBackgroundColor || ""}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiBackgroundColor',kioskUiBackgroundColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                <H5>Primary Color: <Help text={'Primary Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Primary Color"
                    readOnly={false}
                    value={kioskUiButtonPrimaryColor || ""}
                    onChange={(e)=>setkioskUiButtonPrimaryColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  value={kioskUiButtonPrimaryColor || ""}
                    onChange={(e)=>setkioskUiButtonPrimaryColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiButtonPrimaryColor',kioskUiButtonPrimaryColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                <H5>Secondary Color: <Help text={'Secondary Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Secondary Color"
                    readOnly={false}
                    value={kioskUiTextColor || ""}
                    onChange={(e)=>setkioskUiTextColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  value={kioskUiTextColor || ""}
                    onChange={(e)=>setkioskUiTextColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiTextColor',kioskUiTextColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="from-field">
        <br/><br/>
            <div><Switch
            checked={kioskUiLogoBorderEnable}
            onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiLogoBorderEnable',e.target.checked )}
            label="Enable Logo Border" innerLabelChecked="on" innerLabel="off" /></div>
            </div>
       </div>
       <div className="w-33">
        <div className="form-field">
                <H5>Success Color: <Help text={'Success Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Success Color"
                    readOnly={false}
                    value={kioskUiButtonSuccessColor || ""}
                    onChange={(e)=>setkioskUiButtonSuccessColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"value={kioskUiButtonSuccessColor || ""}
                    onChange={(e)=>setkioskUiButtonSuccessColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiButtonSuccessColor',kioskUiButtonSuccessColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                <H5>Button Keypad Color: <Help text={'Button Keypad Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Button Keypad Color"
                    readOnly={false}
                    value={kioskUiButtonKeyColor || ""}
                    onChange={(e)=>setkioskUiButtonKeyColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"value={kioskUiButtonKeyColor || ""}
                    onChange={(e)=>setkioskUiButtonKeyColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiButtonKeyColor',kioskUiButtonKeyColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                <H5>Term Text Color: <Help text={'Term Text Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Term Text Color"
                    readOnly={false}
                    value={kioskUiTermTextColor || ""}
                    onChange={(e)=>setkioskUiTermTextColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text" value={kioskUiTermTextColor || ""}
                    onChange={(e)=>setkioskUiTermTextColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiTermTextColor',kioskUiTermTextColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                        <H5>Logo Border Color: <Help text={'Logo Border Color'}/></H5>
                        <InputGroup
                            type="color"
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter Logo Border Color"
                            readOnly={false}
                            value={kioskUiLogoBorderColor || ""}
                            onChange={(e)=>setkioskUiLogoBorderColor(e.target.value)}
                            rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text" value={kioskUiLogoBorderColor || ""}
                            onChange={(e)=>setkioskUiLogoBorderColor(e.target.value)}/><Button small={true} intent="primary"
                            onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiLogoBorderColor',kioskUiLogoBorderColor )}
                            >change</Button></ButtonGroup>}
                        />
                </div>
        </div>
        <div className="w-33">
        <div className="form-field">
                <H5>Title Text Color: <Help text={'Title Text Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Title Text Color"
                    readOnly={false}
                    value={kioskUiTitleTextColor || ""}
                    onChange={(e)=>setkioskUiTitleTextColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text" value={kioskUiTitleTextColor || ""}
                    onChange={(e)=>setkioskUiTitleTextColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiTitleTextColor',kioskUiTitleTextColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                <H5>Continue Button Text: <Help text={'Continue Button Text Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Continue Button Text Color"
                    readOnly={false}
                    value={kioskUiContinueBtnTextColor || ""}
                    onChange={(e)=>setkioskUiContinueBtnTextColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text" value={kioskUiContinueBtnTextColor || ""}
                    onChange={(e)=>setkioskUiContinueBtnTextColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiContinueBtnTextColor',kioskUiContinueBtnTextColor )}
                    >change</Button></ButtonGroup>}
                />
            </div> 
            <div className="form-field">
                <H5>Error Color: <Help text={'Error Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Error Color"
                    readOnly={false}
                    value={kioskUiButtonErrorColor || ""}
                    onChange={(e)=>setkioskUiButtonErrorColor(e.target.value)}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  value={kioskUiButtonErrorColor || ""}
                    onChange={(e)=>setkioskUiButtonErrorColor(e.target.value)}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiButtonErrorColor',kioskUiButtonErrorColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        <div className="form-field">
                <H5>Button Disabled Color: <Help text={'Button Disabled Color'}/></H5>
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Button Disabled Color"
                    readOnly={false}
                    onChange={(e)=>setkioskUiButtonDisabledColor(e.target.value)}
                    value={kioskUiButtonDisabledColor || ""}
                    rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text" onChange={(e)=>setkioskUiButtonDisabledColor(e.target.value)}
                    value={kioskUiButtonDisabledColor || ""}/><Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiButtonDisabledColor',kioskUiButtonDisabledColor )}
                    >change</Button></ButtonGroup>}
                />
        </div>
        </div>
    </div>

            <div className="row-full-width">            
                    <div className="wi-50">
                    <div className="form-field">
                        <H5>Term Font Size : <Help text={'Term Font Size'}/></H5>               
                        <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiTermTextSize',e.target.value )}>
                        {Array.from(Array(50).keys()).map((index:any)=>(<option selected={kioskUiTermTextSize===index+'px'} value={index+'px'}>{index}px</option>))}
                        </select>   
            </div>
                    </div>
                   
            </div>
     


  
        <div className="row-full-width">
            <div className="w-50">
                <div className="labelzxc"><Switch checked={kioskUiHideCity} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiHideCity',e.target.checked )} disabled={Disabled} label={'Hide city name'} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch checked={kioskUiShowSigSmiley} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiShowSigSmiley',e.target.checked )} disabled={Disabled} label={'Show Smiley In Signature Page'} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch checked={kioskUiHideContBtn} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiHideContBtn',e.target.checked )} disabled={Disabled} label={'Hide continue buttons until phone number complete'} innerLabelChecked="on" innerLabel="off" /></div>
            
            </div> 
            <div className="w-50">
                <div className="labelzxc"><Switch checked={kioskUiTabletView} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiTabletView',e.target.checked )} disabled={Disabled} label={'Check-In Page on Tablet'} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch checked={kioskUiVehicleConfig} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiVehicleConfig',e.target.checked )} disabled={Disabled} label={'Vehicle Kiosk Configuration'} innerLabelChecked="on" innerLabel="off" /></div>
                <div className="labelzxc"><Switch checked={kioskUiliability} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiliability',e.target.checked )} disabled={Disabled} label={'Liability Waiver option on/off'} innerLabelChecked="on" innerLabel="off" /></div>
            </div>     
</div>

         
         <div className="row-full-width">
            <div className="wi-50">
                <div className="form-field">
                  <H5>Just In QR Code</H5>
                  <QRCode value={urls.baseUrlJustIn+'/'+busienssNext.path+'/'+path} renderAs="canvas" />  
                </div> 
        </div>
         </div>

         <div className="form-field">
                    <H5>Just in landing page link</H5>
         
                    <CopyLink url={urls.baseUrlJustIn+'/'+busienssNext.path+'/'+path} />
                </div>   
        
        <div className="form-field">
            <H3>Kiosk screens</H3>
        </div>

    <div className="row-full-width">
      <div className="w-50">

        <div className="form-field">
            <H5>dateofbirth</H5>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'dateofbirth','always',e.target.checked)} checked={routes.dateofbirth ? routes.dateofbirth.alwaysShow : false} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'dateofbirth','enabled',e.target.checked)} checked={routes.dateofbirth ? routes.dateofbirth.enabled : false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'dateofbirth','optional',e.target.checked)} checked={routes.dateofbirth ? routes.dateofbirth.optional : false} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div>
        </div>
        <div className="form-field">
            <H5>email</H5>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'email','always',e.target.checked)} checked={routes.email ? routes.email.alwaysShow :false} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'email','enabled',e.target.checked)} checked={routes.email ? routes.email.enabled :false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'email','optional',e.target.checked)} checked={routes.email ?  routes.email.optional :false} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div>
       </div>
        <div className="form-field">
            <H5>language</H5>
            {/* <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'language','always',e.target.checked)} checked={routes.language.alwaysShow} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div> */}
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'language','enabled',e.target.checked)} checked={routes.language ? routes.language.enabled :false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            {/* <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'language','optional',e.target.checked)} checked={routes.language.optional} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div> */}
        </div>
        <div className="form-field">
            <H5>name</H5>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'name','always',e.target.checked)} checked={routes.name ? routes.name.alwaysShow :false} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'name','enabled',e.target.checked)} checked={routes.name ? routes.name.enabled :false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'name','optional',e.target.checked)} checked={routes.name ? routes.name.optional :false} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div>
        </div>
    </div>
    <div className="w-50">      
        <div className="form-field">
            <H5>vehicles</H5>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'vehicles','always',e.target.checked)} checked={routes.vehicles ?  routes.vehicles.alwaysShow :false} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'vehicles','enabled',e.target.checked)} checked={routes.vehicles ?  routes.vehicles.enabled :false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'vehicles','optional',e.target.checked)} checked={routes.vehicles ?  routes.vehicles.optional :false} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div>
         </div>
        <div className="form-field">
            <H5>zip</H5>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'zip','always',e.target.checked)} checked={routes.zip ? routes.zip.alwaysShow :false} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'zip','enabled',e.target.checked)} checked={routes.zip ? routes.zip.enabled :false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'zip','optional',e.target.checked)} checked={routes.zip ? routes.zip.optional :false} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div>
    </div>
    <div className="form-field">
            <H5>signature</H5>
            {/* <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'signature','always',e.target.checked)} checked={routes.signature.alwaysShow} disabled={Disabled} label={'always show'} innerLabelChecked="on" innerLabel="off" /></div> */}
            <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'signature','enabled',e.target.checked)} checked={routes.signature ? routes.signature.enabled:false} disabled={Disabled} label={'enabled'} innerLabelChecked="on" innerLabel="off" /></div>
            {/* <div className="labelzxc"><Switch onChange={(e:any)=>Handlesetroutes(true,'signature','optional',e.target.checked)} checked={routes.signature.optional} disabled={Disabled} label={'optional'} innerLabelChecked="on" innerLabel="off" /></div> */}
            <div className="labelzxc"><Switch  onChange={(e:any)=>Handlesetroutes(true,'signature','takeMugshot',e.target.checked)} checked={routes.signature ? routes.signature.takeMugshot:false} disabled={Disabled} label={'take picture'} innerLabelChecked="on" innerLabel="off" /></div>
        </div>
</div>
</div>






        <H2>Just In Alerts Set up</H2>
        <div className="row-full-width">
             <div className="w-50">
             <H4>Feed Type</H4>
             <label className="labelzxc"><input checked={feedtype==='s3id'} onChange={(e)=>handleSiteChangesAndUpdate(true,'feedType','s3id')} type="radio" name="type" value={'s3id'}/>s3Id</label>&nbsp;&nbsp;&nbsp;
             <label className="labelzxc"><input  checked={feedtype==='urgid'}  onChange={(e)=>handleSiteChangesAndUpdate(true,'feedType','urgid')} type="radio" name="type" value={'urgid'}/>urgId</label>
            </div>
        </div>
        <div className="row-full-width">
          {feedtype==='s3id'?
         <div className="w-50">
            <div className="form-field">
                <H5>Crush Vehicle Feed #: <Help text={'s3Id'}/></H5>
                <InputGroup
                    disabled={Disabled}
                    large={false}
                    onChange={(e:any)=>sets3Id(e.target.value)}
                    placeholder="Enter s3Id"
                    readOnly={false}
                    value={s3Id || ""}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'s3Id',s3Id)}
                    >change</Button>}
                />
            </div>
          </div>:''}
          {feedtype==='urgid'? <div className="w-50">
                <div className="form-field">
                    <H5>URG Vehicle Feed #: <Help text={'urgId'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter urgId"
                        readOnly={false}
                        value={urgId || ""}
                        onChange={(e:any)=>seturgId(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'urgId',urgId)}
                        >change</Button>}
                    />
                </div>
            </div>:''}
        </div>



{/* justIn ui */}
       
        <div className="form-field">
                <H2>Just In Sign Up UI: <Help text={'Just In Sign Up UI'}/></H2>
                 <div><Switch label="Espanol Button Enable" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'enableExpanolBtn',e.target.checked)} checked={enableExpanolBtn}  innerLabelChecked="on" innerLabel="off" /></div>
                 <div><Switch label="VIP Club Checkbox Enable" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'enableVipClub',e.target.checked)} checked={enableVipClub}  innerLabelChecked="on" innerLabel="off" /></div>
                
        </div>

       {enableVipClub ? <div className="row-full-width">
            <div className="w-50">
            <div className="form-field">
                <H5>englishKeyword: <Help text={'englishKeyword'}/></H5>
                <InputGroup
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter englishKeyword"
                    readOnly={false}
                    value={englishKeyword || ""}
                    onChange={(e:any)=>setenglishKeyword(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'englishKeyword',englishKeyword)}
                    >change</Button>}
                />
            </div>
            <div className="form-field">
                <H5>spanishKeyword: <Help text={'spanishKeyword'}/></H5>
                <InputGroup
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter spanishKeyword"
                    readOnly={false}
                    value={spanishKeyword || ""}
                    onChange={(e:any)=>setspanishKeyword(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'spanishKeyword',spanishKeyword)}
                    >change</Button>}
                />
            </div>
            <div className="form-field">
                    <H5>englishLocation: <Help text={'englishLocation'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter englishLocation"
                        readOnly={false}
                        value={englishLocation || ""}
                        onChange={(e:any)=>setenglishLocation(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'englishLocation',englishLocation)}
                        >change</Button>}
                    />
            </div>
            <div className="form-field">
                    <H5>spanishLocation: <Help text={'spanishLocation'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter spanishLocation"
                        readOnly={false}
                        value={spanishLocation || ""}
                        onChange={(e:any)=>setspanishLocation(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'spanishLocation',spanishLocation)}
                        >change</Button>}
                    />
            </div>
            </div>
            <div className="w-50">
            <div className="form-field">
                <H5>englishVipClubCheckboxText: <Help text={'englishVipClubCheckboxText'}/></H5>
                <InputGroup
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter englishVipClubCheckboxText"
                    readOnly={false}
                    value={englishVipClubCheckboxText || ''}
                    onChange={(e:any)=>setenglishVipClubCheckboxText(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'englishVipClubCheckboxText',englishVipClubCheckboxText)}
                    >change</Button>}
                />
            </div>
            <div className="form-field">
                    <H5>spanishVipClubCheckboxText: <Help text={'spanishVipClubCheckboxText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter spanishVipClubCheckboxText"
                        readOnly={false}
                        value={spanishVipClubCheckboxText || ''}
                        onChange={(e:any)=>setspanishVipClubCheckboxText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'spanishVipClubCheckboxText',spanishVipClubCheckboxText)}
                        >change</Button>}
                    />
            </div>
            <div className="form-field">
                    <H5>englishVipClubHoverText: <Help text={'englishVipClubHoverText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter englishVipClubHoverText"
                        readOnly={false}
                        value={englishVipClubHoverText || ''}
                        onChange={(e:any)=>setenglishVipClubHoverText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'englishVipClubHoverText',englishVipClubHoverText)}
                        >change</Button>}
                    />
            </div>
            <div className="form-field">
                    <H5>spanishVipClubHoverText: <Help text={'spanishVipClubHoverText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter spanishVipClubHoverText"
                        readOnly={false}
                        value={spanishVipClubHoverText || ''}
                        onChange={(e:any)=>setspanishVipClubHoverText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'spanishVipClubHoverText',spanishVipClubHoverText)}
                        >change</Button>}
                    />
            </div>
            </div>
        </div>:''}
        
 
        {/* <div className="form-field">
        <H3>How It Works: <Help text={'How It Works'}/></H3>
        <div className="row-full-width">
            <div className="w-50">
                <H5>English: <Help text={'English'}/></H5>
                <textarea rows={10} cols={50} onChange={(e:any)=>sethelpMenu(e.target.value)}>
                {helpMenu || "If you are returning to this page and want to check the vehicle picks you have already made, then enter your phone number it the bottom box below where it says “Returning to this page? Enter your mobile number to access Dashboard”.<br /><br /> If you haven’t ever entered a vehicle pick, then you will need to pick a Make and Model you want to receive notifications for.  Year range is optional.<br /><br /> Once you are finished entering the vehicle you want to receive notifications for, then enter a valid mobile number.<br /><br /> You can access your Dashboard with the green Next button.  Your Dashboard is where you can edit, renew or delete vehicle picks. "}
                </textarea><br/>
                <Button small={true} intent="primary" onClick={(e:any)=>handleSiteChangesAndUpdate(true,'helpMenu',helpMenu)}>Save</Button>
            </div>
            <div className="w-50">
                <H5>Spanish: <Help text={'Spanish'}/></H5>
                <textarea rows={10}  cols={50} onChange={(e:any)=>sethelpMenus(e.target.value)}>
                {helpMenus || "Si regresa a esta página y desea verificar las selecciones de vehículos que ya realizó, ingrese su número de teléfono en el cuadro inferior donde dice “¿Regresando a esta página? Ingrese su número de teléfono móvil para acceder al Tablero ”.<br /><br />Si nunca ingresó una selección de vehículo, deberá elegir una Marca y Modelo para los que desea recibir notificaciones. El rango de años es opcional.<br /><br />Una vez que haya terminado de ingresar el vehículo para el que desea recibir notificaciones, ingrese un número de teléfono móvil válido.<br /><br />Puede acceder a su Panel de control con el botón verde Siguiente. Su Tablero es donde puede editar, renovar o eliminar selecciones de vehículos."
                }
                </textarea><br/>
                <Button small={true} intent="primary" onClick={(e:any)=>handleSiteChangesAndUpdate(true,'helpMenus',helpMenus)}>Save</Button>
            </div>
        </div>        
       </div> */}

       {/* <div className="row-full-width">
            <div className="w-50">
                <div className="form-field">
                    <H5>howitworksText: <Help text={'howitworksText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter howitworksText"
                        readOnly={false}
                        onChange={(e:any)=>sethowitworksText(e.target.value)}
                        value={howitworksText || "How It Works"}
                        rightElement={<Button small={true} intent="primary" onClick={(e:any)=>handleSiteChangesAndUpdate(true,'howitworksText',howitworksText)}>change</Button>}
                    />
                </div>
                <div className="form-field">
                        <H5>howitworksTexts: <Help text={'howitworksTexts'}/></H5>
                        <InputGroup
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter howitworksTexts"
                            readOnly={false}
                            value={howitworksTexts || "Cómo Funciona"}
                            onChange={(e:any)=>sethowitworksTexts(e.target.value)}
                            rightElement={<Button small={true} intent="primary" onClick={(e:any)=>handleSiteChangesAndUpdate(true,'howitworksTexts',howitworksTexts)}>change</Button>}
                        />
                </div>
            </div>
            <div className="w-50">
                <div className="form-field">
                    <H5>Font Color: <Help text={'Font Color'}/></H5>
                    <InputGroup
                        type="color"
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter Font Color"
                        readOnly={false}
                        value={howitworksColor || "#fff"}
                        onChange={(e:any)=>sethowitworksColor(e.target.value)}
                        rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  onChange={(e:any)=>sethowitworksColor(e.target.value)} value={howitworksColor || "#fff"}/><Button small={true} intent="primary" onClick={(e:any)=>handleSiteChangesAndUpdate(true,'howitworksColor',howitworksColor)}>change</Button></ButtonGroup>}
                    />
                </div>
                <div className="form-field">
                        <H5>Background Color: <Help text={'Background Color'}/></H5>
                        <InputGroup
                            type="color"
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter Background Color"
                            readOnly={false}
                            value={howitworksBgColor || "#0D47F0"}
                            onChange={(e:any)=>sethowitworksBgColor(e.target.value)}
                            rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  onChange={(e:any)=>sethowitworksBgColor(e.target.value)} value={howitworksBgColor || "#0D47F0"}/><Button small={true} intent="primary" onClick={(e:any)=>handleSiteChangesAndUpdate(true,'howitworksBgColor',howitworksBgColor)}>change</Button></ButtonGroup>}
                        />
                </div>
            </div>
       </div>
 

       <div className="row-full-width">
            <div className="w-50">
               <div className="form-field">
                <H5>Image : <Help text={'Image'}/></H5>
                <InputGroup
                    type="file"
                    disabled={Disabled}
                    large={false}
                    readOnly={false}
                    onChange={(f:any)=>FileUploader('howitworksImg','how-it-works',f.target.files) }
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'howitworksImg',howitworksImg)}
                    >change</Button>}
                />
                </div>
        </div>
        <div className="w-50">
               <div className="form-field">
        {howitworksImg!==undefined && howitworksImg!==null && howitworksImg!=='' ? <img alt="img" src={howitworksImg} width={'100%'} height={'100%'}/>:''}
        </div>
        </div>      
    </div> */}
        {/* end image process */}
   
{/* 
        <div className="row-full-width">
            <div className="w-50">
                <div className="form-field">
                    <H5>homeButtonText : <Help text={'homeButtonText'}/></H5>
                    <InputGroup
                        type="text"
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter homeButtonText"
                        readOnly={false}
                        value={homeButtonText || "Home"}
                        onChange={(e:any)=>sethomeButtonText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'homeButtonText',homeButtonText)}
                        >change</Button>}
                    />
                </div>
            </div>
            <div className="w-50">
                <div className="form-field">
                    <H5>homeButtonTextSpanish : <Help text={'homeButtonTextSpanish'}/></H5>
                    <InputGroup
                        type="text"
                        placeholder="Enter homeButtonTextSpanish"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={homeButtonTextSpanish  || "Hogar"}
                        onChange={(e:any)=>sethomeButtonTextSpanish(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'homeButtonTextSpanish',homeButtonTextSpanish )}
                        >change</Button>}
                    />
                </div>
            </div>
        </div> */}
            <div className="row-full-width">
             <div  className="w-50">
                <div className="form-field">
                  <H3>Home Button : <Help text={'Home Button'}/></H3>
                <div><Switch   checked={homeBtnEnable ? homeBtnEnable : false} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'homeBtnEnable',e.target.checked)} label="Home Button Enable" innerLabelChecked="on" innerLabel="off" /></div>
             </div>
                </div>
               {homeBtnEnable ? <div className="w-50">
                    <div className="form-field">
                        <H5> Home BtnUrl: <Help text={'homeBtnUrl'}/></H5>
                        <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter homeBtnUrl"
                        readOnly={false}
                        value={homeBtnUrl || ""}
                        onChange={(e:any)=>sethomeBtnUrl(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'homeBtnUrl',homeBtnUrl)}
                        >change</Button>}
                        />
                 
            </div>    </div> :''}
       </div>
     <div className="row-full-width">
             <div className="w-50"> 
                
             {homeBtnEnable ?<div className="form-field">
                    <H5>Home Button Color : <Help text={'homeButtonColor'}/></H5>
                    <InputGroup
                        type="color"
                        placeholder="Enter home Button Color"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={homeButtonColor || "#ffffff"}
                        onChange={(e:any)=>sethomeButtonColor(e.target.value)}
                        rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"    onChange={(e:any)=>sethomeButtonColor(e.target.value)} value={homeButtonColor || "#ffffff"}/><Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'homeButtonColor',homeButtonColor )}
                        >change</Button></ButtonGroup>}
                    />
                </div>:''}

                <div className="form-field">
                    <H5>Sign Up Ui Background Color : <Help text={'Font Color'}/></H5>
                    <InputGroup
                        type="color"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={jiSignUpUiBackgroundColor || ""}
                        onChange={(e:any)=>setjiSignUpUiBackgroundColor(e.target.value)}
                        rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  onChange={(e:any)=>setjiSignUpUiBackgroundColor(e.target.value)} value={jiSignUpUiBackgroundColor || ""}/><Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'jiSignUpUiBackgroundColor',jiSignUpUiBackgroundColor )}
                        >change</Button></ButtonGroup>}
                    />
                </div>   

            </div>
            <div className="w-50">

          {homeBtnEnable?  <div className="form-field">
                <H5>Home Button Background Color : <Help text={'homeButtonBgColor'}/></H5>
                    <InputGroup
                        type="color"
                        placeholder="Enter home Button bg Color"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={homeButtonBgColor || "#ffffff"}
                        onChange={(e:any)=>sethomeButtonBgColor(e.target.value)}
                        rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"    onChange={(e:any)=>sethomeButtonBgColor(e.target.value)} value={homeButtonBgColor || "#ffffff"}/><Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'homeButtonBgColor',homeButtonBgColor )}
                        >change</Button></ButtonGroup>}
                    />
                </div> :''}          
                  
                <div className="form-field">
                <H5>Sign Up Ui Font Color : <Help text={'jiSignUpUiFontColor'}/></H5>
                    <InputGroup
                        type="color"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={jiSignUpUiFontColor || ""}
                        onChange={(e:any)=>setjiSignUpUiFontColor(e.target.value)}
                        rightElement={<ButtonGroup><InputGroup  className="rightInput" type="text"  onChange={(e:any)=>setjiSignUpUiFontColor(e.target.value)} value={jiSignUpUiFontColor || ""}/><Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'jiSignUpUiFontColor',jiSignUpUiFontColor )}
                        >change</Button></ButtonGroup>}
                    />
                </div>
            </div>
        </div>

        

        <H3>Logo : <Help text={'Logo'}/></H3>
        <div className="row-full-width">
            <div className="w-50">
             <div className="form-field">
                <H5>widthForDesktop : <Help text={'widthForDesktop'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    readOnly={false}
                    value={widthForDesktop || "370px"}
                    onChange={(e:any)=>setwidthForDesktop(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'widthForDesktop',widthForDesktop )}
                    >change</Button>}
                />
             </div>
             <div className="form-field">
                    <H5>widthForMobile : <Help text={'widthForMobile'}/></H5>
                    <InputGroup
                        type="text"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={widthForMobile || "370px"}
                        onChange={(e:any)=>setwidthForMobile(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'widthForMobile',widthForMobile )}
                        >change</Button>}
                    />
             </div>
            </div>
            <div className="w-50">
                <div className="form-field">
                    <H5>heightForDesktop : <Help text={'heightForDesktop'}/></H5>
                    <InputGroup
                        type="text"
                        disabled={Disabled}
                        large={false}
                        readOnly={false}
                        value={heightForDesktop || "130px"}
                        onChange={(e:any)=>setheightForDesktop(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'heightForDesktop',heightForDesktop )}
                        >change</Button>}
                    />
                </div>
                <div className="form-field">
                        <H5>heightForMobile : <Help text={'heightForMobile'}/></H5>
                        <InputGroup
                            type="text"
                            disabled={Disabled}
                            large={false}
                            readOnly={false}
                            value={heightForMobile || "130px"}
                            onChange={(e:any)=>setheightForMobile(e.target.value)}
                            rightElement={<Button small={true} intent="primary"
                            onClick={(e:any)=>handleSiteChangesAndUpdate(true,'heightForMobile',heightForMobile )}
                            >change</Button>}
                        />
                </div>
            </div>
        </div>
    
        <H3>Title : <Help text={'Title'}/></H3>
        <div className="row-full-width">
            <div className="w-50">
                <div className="form-field">
                    <H5>Title Font Size for Desktop : <Help text={'Title Font Size for Desktop'}/></H5>               
                    <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'titleSize',e.target.value )}>
                        {Array.from(Array(50).keys()).map((index:any)=>(<option selected={titleSize===index+'px'} value={index+'px'}>{index}px</option>))}
                        </select>   
                    </div>
                    <div className="form-field">
                    <H5>Title Font Size for Mobile : <Help text={'Title Font Size for Mobile'}/></H5>
                    <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'titleSizeM',e.target.value )}>
                        {Array.from(Array(50).keys()).map((index:any)=>(<option selected={titleSizeM===index+'px'} value={index+'px'}>{index}px</option>))}
                        </select> 
                </div>
            </div>
            <div className="w-50">                
                <div className="form-field">
                        <H5>Title Font Type : <Help text={'Title Font Type'}/></H5>
                        <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'titleType',e.target.value )}>
                        <option selected={titleType==='Verdana, sans-serif'} value={'Verdana, sans-serif'}>Verdana, sans-serif</option>
                        <option selected={titleType==="'Times New Roman', Times, serif"}value={"'Times New Roman', Times, serif"}>'Times New Roman', Times, serif</option>
                        <option selected={titleType==="Arial, Helvetica, sans-serif"} value={'Arial, Helvetica, sans-serif'}>Arial, Helvetica, sans-serif</option>
                        <option selected={titleType==="'Lucida Console', 'Courier New', monospace"} value={"'Lucida Console', 'Courier New', monospace"}>'Lucida Console', 'Courier New', monospace</option>
                        </select> 
                </div>
            </div>
        </div>


    <div className="row-full-width">
        <div className="w-50">
            <div className="form-field">
                    <H5>titleText: <Help text={'titleText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter titleText"
                        readOnly={false}
                        value={titleText || "Sign up for Just In vehicle alerts at  "}
                        onChange={(e:any)=>settitleText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'titleText',titleText )}
                        >change</Button>}
                    />
            </div>
        </div>
        <div className="w-50">  
            <div className="form-field">
                    <H5>titleTextSpanish: <Help text={'titleTextSpanish'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter titleTextSpanish"
                        readOnly={false}
                        value={titleTextSpanish || "Regístrese para recibir alertas de texto de Fresh Stock en "}
                        onChange={(e:any)=>settitleTextSpanish(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'titleTextSpanish',titleTextSpanish )}
                        >change</Button>}
                    />
            </div>
        </div>
    </div>

    {/* <H3>Returning to this page? Customization : <Help text={'Returning to this page? Customization'}/></H3>
    <div className="row-full-width">
        <div className="w-50">
            <div className="form-field">
                    <H5>Font Size for Desktop : <Help text={'Font Size for Desktop'}/></H5>               
                    <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'retToPageSize',e.target.value)}>
                    {Array.from(Array(50).keys()).map((index:any)=>(<option selected={retToPageSize===index+'px'} value={index+'px'}>{index}px</option>))}
                    </select>   
            </div>
            <div className="form-field">
                    <H5>Font Size for Mobile : <Help text={'Font Size for Mobile'}/></H5>
                    <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'retToPageSizeM',e.target.value)}>
                    {Array.from(Array(50).keys()).map((index:any)=>(<option selected={retToPageSizeM===index+'px'} value={index+'px'}>{index}px</option>))}
                    </select> 
            </div>
        </div>
        <div className="w-50">
            <div className="form-field">
                    <H5>Font Type : <Help text={'Font Type'}/></H5>
                    <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'retToPageType',e.target.value )}>
                    <option selected={retToPageType==='Verdana, sans-serif'} value={'Verdana, sans-serif'}>Verdana, sans-serif</option>
                    <option selected={retToPageType==="'Times New Roman', Times, serif"}value={"'Times New Roman', Times, serif"}>'Times New Roman', Times, serif</option>
                    <option selected={retToPageType==="Arial, Helvetica, sans-serif"} value={'Arial, Helvetica, sans-serif'}>Arial, Helvetica, sans-serif</option>
                    <option selected={retToPageType==="'Lucida Console', 'Courier New', monospace"} value={"'Lucida Console', 'Courier New', monospace"}>'Lucida Console', 'Courier New', monospace</option>
                    </select> 
            </div>  
        </div>
    </div>
        
    <div className="row-full-width">
        <div className="w-50">
            <div className="form-field">
                    <H5>retToPageText: <Help text={'retToPageText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter retToPageText"
                        readOnly={false}
                        value={retToPageText || "Returning to this page?"}
                        onChange={(e:any)=>setretToPageText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'retToPageText',retToPageText)}
                        >change</Button>}
                    />
            </div>          
        </div>
        <div className="w-50">
          <div className="form-field">
                    <H5>retToPageTextSpanish: <Help text={'retToPageTextSpanish'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter retToPageTextSpanish"
                        readOnly={false}
                        onChange={(e:any)=>setretToPageTextSpanish(e.target.value)}
                        value={retToPageTextSpanish || "Ingrese su número de teléfono móvil"}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'retToPageTextSpanish',retToPageTextSpanish)}
                        >change</Button>}
                    />
            </div>
        </div>
    </div> */}
        
    {/* <H3>Enter your mobile number to access Dashboard : <Help text={'Enter your mobile number to access Dashboard'}/></H3>
    <div className="row-full-width">
        <div className="w-50">
        <div className="form-field">
                <H5>Font Size for Desktop : <Help text={'Font Size for Desktop'}/></H5>               
                <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'accessDashboardSize',e.target.value)}>
                   {Array.from(Array(50).keys()).map((index:any)=>(<option selected={accessDashboardSize===index+'px'} value={index+'px'}>{index}px</option>))}
                 </select>   
        </div>
        <div className="form-field">
                <H5>Font Size for Mobile : <Help text={'Font Size for Mobile'}/></H5>
                <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'accessDashboardSizeM',e.target.value)}>
                   {Array.from(Array(50).keys()).map((index:any)=>(<option selected={accessDashboardSizeM===index+'px'} value={index+'px'}>{index}px</option>))}
                 </select> 
        </div>
        </div>
        <div className="w-50">
            <div className="form-field">
                    <H5>Font Type : <Help text={'Font Type'}/></H5>
                    <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'accessDashboardType',e.target.value )}>
                    <option selected={accessDashboardType==='Verdana, sans-serif'} value={'Verdana, sans-serif'}>Verdana, sans-serif</option>
                    <option selected={accessDashboardType==="'Times New Roman', Times, serif"}value={"'Times New Roman', Times, serif"}>'Times New Roman', Times, serif</option>
                    <option selected={accessDashboardType==="Arial, Helvetica, sans-serif"} value={'Arial, Helvetica, sans-serif'}>Arial, Helvetica, sans-serif</option>
                    <option selected={accessDashboardType==="'Lucida Console', 'Courier New', monospace"} value={"'Lucida Console', 'Courier New', monospace"}>'Lucida Console', 'Courier New', monospace</option>
                    </select> 
            </div>
        </div>
    </div>
       
    <div className="row-full-width">
        <div className="w-50">
            <div className="form-field">
                    <H5>accessDashboardText: <Help text={'accessDashboardText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter accessDashboardText"
                        readOnly={false}
                        onChange={(e:any)=>setaccessDashboardText(e.target.value)}
                        value={accessDashboardText || "Enter your mobile number to access Dashboard"}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'accessDashboardText',accessDashboardText)}
                        >change</Button>}
                    />
            </div>
        </div>
        <div className="w-50">
            <div className="form-field">
                    <H5>accessDashboardTextSpanish: <Help text={'accessDashboardTextSpanish'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter accessDashboardTextSpanish"
                        readOnly={false}
                        onChange={(e:any)=>setaccessDashboardTextSpanish(e.target.value)}
                        value={accessDashboardTextSpanish || "Ingrese su número de teléfono móvil"}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'accessDashboardTextSpanish',accessDashboardTextSpanish)}
                        >change</Button>}
                    />
            </div>
        </div>
    </div> */}
    <div className="labelzxc"><Switch onChange={(e:any)=>handleSiteChangesAndUpdate(true,'enableItalic',e.target.checked)}  checked={enableItalic} disabled={Disabled} label={'Enable Italic'} innerLabelChecked="on" innerLabel="off" /></div>
    
    <H3>Description : <Help text={'Description'}/></H3>
        <div className="row-full-width">
            <div className="w-50">
                <div className="form-field">
                    <H5>Description Font Size for Desktop: <Help text={'Description Font Size for Desktop'}/></H5>               
                    <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'descSize',e.target.value)}>
                        {Array.from(Array(50).keys()).map((index:any)=>(<option selected={descSize===index+'px'} value={index+'px'}>{index}px</option>))}
                        </select>   
                </div>
                <div className="form-field">
                        <H5>Description Font Size for Mobile : <Help text={'Description Font Size for Mobile'}/></H5>
                        <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'descSizeM',e.target.value)}>
                        {Array.from(Array(50).keys()).map((index:any)=>(<option selected={descSizeM ===index+'px'} value={index+'px'}>{index}px</option>))}
                        </select> 
                </div>
            </div>
            <div className="w-50">
                <div className="form-field">
                    <H5>Description Font Type : <Help text={'Description Font Type'}/></H5>
                    <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'descType',e.target.value )}>
                    <option selected={descType==='Verdana, sans-serif'} value={'Verdana, sans-serif'}>Verdana, sans-serif</option>
                    <option selected={descType==="'Times New Roman', Times, serif"}value={"'Times New Roman', Times, serif"}>'Times New Roman', Times, serif</option>
                    <option selected={descType==="Arial, Helvetica, sans-serif"} value={'Arial, Helvetica, sans-serif'}>Arial, Helvetica, sans-serif</option>
                    <option selected={descType==="'Lucida Console', 'Courier New', monospace"} value={"'Lucida Console', 'Courier New', monospace"}>'Lucida Console', 'Courier New', monospace</option>
                        </select> 
                </div>
            </div>
        </div>

        
        <div className="row-full-width">
            <div className="w-50">
                <div className="form-field">
                    <H5>descText: <Help text={'descText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter descText"
                        readOnly={false}
                        value={descText || "Get Alerts for newly-added vehicles, "}
                        onChange={(e:any)=>setdescText(e.target.value)}
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'descText',descText)}
                        >change</Button>}
                    />
                </div>
                <div className="form-field">
                        <H5>descTextSecondline: <Help text={'descTextSecondline'}/></H5>
                        <InputGroup
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter descTextSecondline"
                            readOnly={false}
                            onChange={(e:any)=>setdescTextSecondline(e.target.value)}
                            value={descTextSecondline || "Straight to your phone! We'll send you a text when it arrives."}
                            rightElement={<Button small={true} intent="primary"
                            onClick={(e:any)=>handleSiteChangesAndUpdate(true,'descTextSecondline',descTextSecondline)}
                            >change</Button>}
                        />
                </div>
            </div>
            <div className="w-50">
                <div className="form-field">
                        <H5>descTextSpanish: <Help text={'descTextSpanish'}/></H5>
                        <InputGroup
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter descTextSpanish"
                            readOnly={false}
                            onChange={(e:any)=>setdescTextSpanish(e.target.value)}
                            value={descTextSpanish || "Regístrese para recibir alertas de texto de Fresh Stock en "}
                            rightElement={<Button small={true} intent="primary"
                            onClick={(e:any)=>handleSiteChangesAndUpdate(true,'descTextSpanish',descTextSpanish)}
                            >change</Button>}
                        />
                </div>
                <div className="form-field">
                        <H5>descTextSpanishSecondline: <Help text={'descTextSpanishSecondline'}/></H5>
                        <InputGroup
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter descTextSpanishSecondline"
                            readOnly={false}
                            onChange={(e:any)=>setdescTextSpanishSecondline(e.target.value)}
                            value={
                                descTextSpanishSecondline || "Directo a su teléfono! Le enviaremos un mensaje de texto cuando llegue"
                            }
                            rightElement={<Button small={true} intent="primary"
                            onClick={(e:any)=>handleSiteChangesAndUpdate(true,'descTextSpanishSecondline',descTextSpanishSecondline)}
                            >change</Button>}
                        />
                </div>
            </div>
        </div>
       
        <H3>Terms And Conditions : <Help text={'Terms And Conditions'}/></H3>    
        <div className="row-full-width">
        <div className="w-50">
            <div className="form-field">
                    <H5>Font Size for Desktop : <Help text={'Font Size for Desktop'}/></H5>               
                    <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'tncSize',e.target.value)}>
                    {Array.from(Array(50).keys()).map((index:any)=>(<option selected={tncSize===index+'px'} value={index+'px'}>{index}px</option>))}
                    </select>   
                    </div>
                    <div className="form-field">
                    <H5>Font Size for Mobile : <Help text={'Font Size for Mobile'}/></H5>
                    <select className="selectTimezon"  onChange={(e:any)=>handleSiteChangesAndUpdate(true,'tncSizeM',e.target.value)}>
                    {Array.from(Array(50).keys()).map((index:any)=>(<option selected={tncSizeM===index+'px'}  value={index+'px'}>{index}px</option>))}
                    </select> 
            </div>
        </div>
        <div className="w-50">
            <div className="form-field">
                    <H5>Font Type : <Help text={'Font Type'}/></H5>
                    <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'tncType',e.target.value )}>
                    <option selected={tncType==='Verdana, sans-serif'} value={'Verdana, sans-serif'}>Verdana, sans-serif</option>
                    <option selected={tncType==="'Times New Roman', Times, serif"}value={"'Times New Roman', Times, serif"}>'Times New Roman', Times, serif</option>
                    <option selected={tncType==="Arial, Helvetica, sans-serif"} value={'Arial, Helvetica, sans-serif'}>Arial, Helvetica, sans-serif</option>
                    <option selected={tncType==="'Lucida Console', 'Courier New', monospace"} value={"'Lucida Console', 'Courier New', monospace"}>'Lucida Console', 'Courier New', monospace</option>
                    </select> 
            </div>
        </div>
        </div>

        {/* <div className="row-full-width">
         <div className="w-50">
            <div className="form-field">
                    <H5>tncText: <Help text={'tncText'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter tncText"
                        readOnly={false}
                        onChange={(e)=>settncText(e.target.value)}
                        value={
                            tncText ||
                            "By selecting Next, you are signing up to receive automated text alerts and marketing calls from an autodialer system or live person. Consent to receive automated text alerts not required for purchase. Upon completion of this form, you will receive a text message with instructions to confirm your opt-in. Approx 31 msg/mo & Data Rates May Apply. Reply HELP for HELP, STOP ALL to cancel."
                        }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'tncText',tncText )}
                        >change</Button>}
                    />
            </div>
        </div>
        <div className="w-50">
            <div className="form-field">
                    <H5>tncTextSpanish: <Help text={'tncTextSpanish'}/></H5>
                    <InputGroup
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter tncTextSpanish"
                        readOnly={false}
                        onChange={(e)=>settncTextSpanish(e.target.value)}
                        value={
                            tncTextSpanish ||
                            "Al seleccionar Siguiente, se registra para recibir alertas de texto automatizadas y llamadas de marketing de un sistema de marcación automática o una persona en vivo. No se requiere consentimiento para recibir alertas de texto automáticas para la compra. Al completar este formulario, recibirá un mensaje de texto con instrucciones para confirmar su suscripción. Se pueden aplicar hasta 31 msg / mes y tarifas de datos. Responda HELP para HELP, STOP ALL para cancelar."
                        }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'tncTextSpanish',tncTextSpanish )}
                        >change</Button>}
                    />
            </div>
         </div>   
        </div> */}

    
    <div className="row-full-width">
        <div className="w-50">
        <div className="form-field">
                <H5>Kiosk Logo Width: <Help text={'Kiosk Logo Width'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Kiosk Logo Width"
                    readOnly={false}
                    value={kioskUiLogoWidth || ""}
                    onChange={(e)=>setkioskUiLogoWidth(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiLogoWidth',kioskUiLogoWidth )}
                    >change</Button>}
                />
        </div>      
        </div>

        <div  className="w-50">
        <div className="form-field">
                <H5>Kiosk Logo Height: <Help text={'Kiosk Logo Height'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Kiosk Logo Height"
                    readOnly={false}
                    value={kioskUiLogoHeight || ""}
                    onChange={(e)=>setkioskUiLogoHeight(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiLogoHeight',kioskUiLogoHeight )}
                    >change</Button>}
                />
        </div>
        </div>
    </div>
        <div className="form-field">
            <H5>Show Kiosk Advertising</H5>
                <div className="labelzxc">
                    <Switch onChange={(e:any)=>setEnableKioskAdvertising(!EnableKioskAdvertising)} 
                    checked={EnableKioskAdvertising} 
                    disabled={Disabled} 
                    label={'show Kiosk Advertising'} 
                    innerLabelChecked="on" 
                    innerLabel="off" />
                </div>
          </div>   
{EnableKioskAdvertising===true ? <div>
<div className="row-full-width">
     <div className="w-50">
        <div className="form-field">
                <H5>Advertising Width: <Help text={'Advertising Width'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Advertising Width"
                    readOnly={false}
                    value={kioskUiAdvWidth || ""}
                    onChange={(e)=>setkioskUiAdvWidth(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiAdvWidth',kioskUiAdvWidth )}
                    >change</Button>}
                />
        </div>
        <div className="form-field">
                <H5>Advertising Height: <Help text={'Advertising Height'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Advertising Height"
                    readOnly={false}
                    value={kioskUiAdvHeight || ""}
                    onChange={(e)=>setkioskUiAdvHeight(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiAdvHeight',kioskUiAdvHeight )}
                    >change</Button>}
                />
        </div>
        <div className="form-field">
                <H5>Advertising Margin Top: <Help text={'Advertising Margin Top'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Advertising Margin Top"
                    readOnly={false}
                    value={kioskUiAdvTop || ""}
                    onChange={(e)=>setkioskUiAdvTop(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiAdvTop',kioskUiAdvTop )}
                    >change</Button>}
                />
        </div>
    </div>
<div className="w-50">
        <div className="form-field">
                <H5>Advertising Margin Bottom: <Help text={'Advertising Margin Bottom'}/></H5>
                <InputGroup
                    type="text"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Advertising Margin Bottom"
                    readOnly={false}
                    value={kioskUiAdvBottom || ""}
                    onChange={(e)=>setkioskUiAdvBottom(e.target.value)}
                    rightElement={<Button small={true} intent="primary"
                    onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiAdvBottom',kioskUiAdvBottom )}
                    >change</Button>}
                />
        </div>
       
    </div>
</div>


        <div className="form-field">
                <H5>Advertising Slide Delay Second : <Help text={'Advertising Slide Delay Second'}/></H5>               
                <select className="selectTimezon" onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiAdvDelaySec',e.target.value )}>
                   {Array.from(Array(20).keys()).map((index:any)=>(<option selected={kioskUiAdvDelaySec===index+'px'} value={index}>{index} Second(s)</option>))}
                 </select>   
        </div>



        
    <div className="form-field">
            <H2>Kiosk Inside Advertising</H2>
         <div><Switch  checked={kioskUiHideAdvIn} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiHideAdvIn',e.target.checked )} label="Hide Slider" innerLabelChecked="on" innerLabel="off" /></div>
        
        <div className="row-full-width">
            <div className="w-50">
            <div className="row pb-40"><span className="col-md-2">File 1:</span> 
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile1"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile1','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile1',kioskUiInAdvFile1 )} 
                        >change</Button>}
                    />
                     {kioskUiInAdvFile1!==undefined && kioskUiInAdvFile1!==null && kioskUiInAdvFile1!=='' ? <img alt="img" src={kioskUiInAdvFile1} width={100} height={100}/>:''}
                    </span>
            </div>

            <div className="row pb-40"><span className="col-md-2">File 2:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile2"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile2','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile2',kioskUiInAdvFile2 )} 
                        >change</Button>}
                    />
                     {kioskUiInAdvFile2!==undefined && kioskUiInAdvFile2!==null && kioskUiInAdvFile2!=='' ? <img alt="img" src={kioskUiInAdvFile2} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 3:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile3"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile3','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile3',kioskUiInAdvFile3 )} 
                        >change</Button>}
                    />
                     {kioskUiInAdvFile3!==undefined && kioskUiInAdvFile3!==null && kioskUiInAdvFile3!=='' ? <img alt="img" src={kioskUiInAdvFile3} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 4:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile4"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile4','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile4',kioskUiInAdvFile4 )} 
                        >change</Button>}
                    />
                     {kioskUiInAdvFile4!==undefined && kioskUiInAdvFile4!==null && kioskUiInAdvFile4!=='' ? <img alt="img" src={kioskUiInAdvFile4} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 5:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile5"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile5','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile5',kioskUiInAdvFile5 )} 
                        >change</Button>}
                    />
                     {kioskUiInAdvFile5!==undefined && kioskUiInAdvFile5!==null && kioskUiInAdvFile5!=='' ? <img alt="img" src={kioskUiInAdvFile5} width={100} height={100}/>:''}
                    </span>
            </div>
            </div>
            <div className="w-50">
                
                    <div className="row pb-40"><span className="col-md-2">File 6:</span>   
                        <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile6"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile6','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile6',kioskUiInAdvFile6 )} 
                        >change</Button>}
                        />
                        {kioskUiInAdvFile6!==undefined && kioskUiInAdvFile6!==null && kioskUiInAdvFile6!=='' ? <img alt="img" src={kioskUiInAdvFile6} width={100} height={100}/>:''}
                        </span>
                    </div>
                    <div className="row pb-40"><span className="col-md-2">File 7:</span>   
                        <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile7"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile7','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile7',kioskUiInAdvFile7 )} 
                        >change</Button>}
                        />
                        {kioskUiInAdvFile7!==undefined && kioskUiInAdvFile7!==null && kioskUiInAdvFile7!=='' ? <img alt="img" src={kioskUiInAdvFile7} width={100} height={100}/>:''}
                        </span>
                    </div>
                    <div className="row pb-40"><span className="col-md-2">File 8:</span>   
                        <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiInAdvFile8"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile8','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile8',kioskUiInAdvFile8 )} 
                        >change</Button>}
                        />
                        {kioskUiInAdvFile8!==undefined && kioskUiInAdvFile8!==null && kioskUiInAdvFile8!=='' ? <img alt="img" src={kioskUiInAdvFile8} width={100} height={100}/>:''}
                        </span>
                    </div>
                    <div className="row pb-40"><span className="col-md-2">File 9:</span>   
                        <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder=" kioskUiInAdvFile9"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile9','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile9',kioskUiInAdvFile9 )} 
                        >change</Button>}
                        />
                        {kioskUiInAdvFile9!==undefined && kioskUiInAdvFile9!==null && kioskUiInAdvFile9!=='' ? <img alt="img" src={kioskUiInAdvFile9} width={100} height={100}/>:''}
                        </span>
                    </div>
                        <div className="row pb-40"><span className="col-md-2">File 10:</span>   
                        <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="Enter Kiosk Logo Height"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiInAdvFile10','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiInAdvFile10',kioskUiInAdvFile10 )} 
                        >change</Button>}
                        />
                        {kioskUiInAdvFile10!==undefined && kioskUiInAdvFile10!==null && kioskUiInAdvFile10!=='' ? <img alt="img" src={kioskUiInAdvFile10} width={100} height={100}/>:''}
                        </span>
                        </div>
                    </div>
        </div>
    </div>

      
         <div className="form-field">
            <H2>Kiosk Outside Advertising</H2>
         <div><Switch checked={kioskUiHideAdvOut} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiHideAdvOut',e.target.checked )} label="Hide Slider" innerLabelChecked="on" innerLabel="off" /></div>
        
                 
    <div className="row-full-width">
        <div className="w-50">

        <div className="row pb-40"><span className="col-md-2">File 1:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile1"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile1','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile1',kioskUiOutAdvFile1 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile1!==undefined && kioskUiOutAdvFile1!==null && kioskUiOutAdvFile1!=='' ? <img alt="img" src={kioskUiOutAdvFile1} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 2:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile2"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile2','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile2',kioskUiOutAdvFile2 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile2!==undefined && kioskUiOutAdvFile2!==null && kioskUiOutAdvFile2!=='' ? <img alt="img" src={kioskUiOutAdvFile2} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 3:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile3"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile3','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile3',kioskUiOutAdvFile3 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile3!==undefined && kioskUiOutAdvFile3!==null && kioskUiOutAdvFile3!=='' ? <img alt="img" src={kioskUiOutAdvFile3} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 4:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile4"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile4','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile4',kioskUiOutAdvFile4 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile4!==undefined && kioskUiOutAdvFile4!==null && kioskUiOutAdvFile4!=='' ? <img alt="img" src={kioskUiOutAdvFile4} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 5:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile5"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile5','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile5',kioskUiOutAdvFile5 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile5!==undefined && kioskUiOutAdvFile5!==null && kioskUiOutAdvFile5!=='' ? <img alt="img" src={kioskUiOutAdvFile5} width={100} height={100}/>:''}
                    </span>
            </div>
        </div> 
        <div className="w-50">
        <div className="row pb-40"><span className="col-md-2">File 6:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile6"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile6','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile6',kioskUiOutAdvFile6 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile6!==undefined && kioskUiOutAdvFile6!==null && kioskUiOutAdvFile6!=='' ? <img alt="img" src={kioskUiOutAdvFile6} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 7:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile7"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile7','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile7',kioskUiOutAdvFile7 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile7!==undefined && kioskUiOutAdvFile7!==null && kioskUiOutAdvFile7!=='' ? <img alt="img" src={kioskUiOutAdvFile7} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 8:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile8"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile8','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile8',kioskUiOutAdvFile8 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile8!==undefined && kioskUiOutAdvFile8!==null && kioskUiOutAdvFile8!=='' ? <img alt="img" src={kioskUiOutAdvFile8} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 9:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile9"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile9','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile9',kioskUiOutAdvFile9 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile9!==undefined && kioskUiOutAdvFile9!==null && kioskUiOutAdvFile9!=='' ? <img alt="img" src={kioskUiOutAdvFile9} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 10:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiOutAdvFile10"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiOutAdvFile10','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiOutAdvFile10',kioskUiOutAdvFile10 )} 
                        >change</Button>}
                    />
                     {kioskUiOutAdvFile10!==undefined && kioskUiOutAdvFile10!==null && kioskUiOutAdvFile10!=='' ? <img alt="img" src={kioskUiOutAdvFile10} width={100} height={100}/>:''}
                    </span>
            </div>
        </div>                   
    </div>
  </div>
         <div className="form-field">
            <H2>Kiosk Signature Inside Advertising</H2>
         <div><Switch checked={kioskUiSigHideAdvIn} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigHideAdvIn',e.target.checked )} label="Hide Slider" innerLabelChecked="on" innerLabel="off" /></div>
      
              
    <div className="row-full-width">
        <div className="w-50">
         <div className="row pb-40"><span className="col-md-2">File 1:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile1"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile1','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile1',kioskUiSigInAdvFile1 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile1!==undefined && kioskUiSigInAdvFile1!==null && kioskUiSigInAdvFile1!=='' ? <img alt="img" src={kioskUiSigInAdvFile1} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 2:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile2"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile2','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile2',kioskUiSigInAdvFile2 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile2!==undefined && kioskUiSigInAdvFile2!==null && kioskUiSigInAdvFile2!=='' ? <img alt="img" src={kioskUiSigInAdvFile2} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 3:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile3"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile3','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile3',kioskUiSigInAdvFile3 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile3!==undefined && kioskUiSigInAdvFile3!==null && kioskUiSigInAdvFile3!=='' ? <img alt="img" src={kioskUiSigInAdvFile3} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 4:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile4"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile4','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile4',kioskUiSigInAdvFile4 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile4!==undefined && kioskUiSigInAdvFile4!==null && kioskUiSigInAdvFile4!=='' ? <img alt="img" src={kioskUiSigInAdvFile4} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 5:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile5"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile5','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile5',kioskUiSigInAdvFile5 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile5!==undefined && kioskUiSigInAdvFile5!==null && kioskUiSigInAdvFile5!=='' ? <img alt="img" src={kioskUiSigInAdvFile5} width={100} height={100}/>:''}
                    </span>
            </div>
    </div>
    <div className="w-50">
   
            <div className="row pb-40"><span className="col-md-2">File 6:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile6"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile6','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile6',kioskUiSigInAdvFile6 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile6!==undefined && kioskUiSigInAdvFile6!==null && kioskUiSigInAdvFile6!=='' ? <img alt="img" src={kioskUiSigInAdvFile6} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 7:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile7"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile7','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile7',kioskUiSigInAdvFile7 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile7!==undefined && kioskUiSigInAdvFile7!==null && kioskUiSigInAdvFile7!=='' ? <img alt="img" src={kioskUiSigInAdvFile7} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 8:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile8"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile8','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile8',kioskUiSigInAdvFile8 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile8!==undefined && kioskUiSigInAdvFile8!==null && kioskUiSigInAdvFile8!=='' ? <img alt="img" src={kioskUiSigInAdvFile8} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 9:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile9"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile9','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile9',kioskUiInAdvFile1 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile9!==undefined && kioskUiSigInAdvFile9!==null && kioskUiSigInAdvFile9!=='' ? <img alt="img" src={kioskUiSigInAdvFile9} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 10:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigInAdvFile10"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigInAdvFile10','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigInAdvFile10',kioskUiSigInAdvFile10 )} 
                        >change</Button>}
                    />
                     {kioskUiSigInAdvFile10!==undefined && kioskUiSigInAdvFile10!==null && kioskUiSigInAdvFile10!=='' ? <img alt="img" src={kioskUiSigInAdvFile10} width={100} height={100}/>:''}
                    </span>
            </div>
          </div>         
        </div>
    </div>


         <div className="form-field">
            <H2>Kiosk Signature Outside Advertising</H2>
         <div><Switch checked={kioskUiSigHideAdvOut} onChange={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigHideAdvOut',e.target.checked )} label="Hide Slider" innerLabelChecked="on" innerLabel="off" /></div>
       
    <div className="row-full-width">
        <div className="w-50">
        <div className="row pb-40"><span className="col-md-2">File 1:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile1"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile1','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile1',kioskUiSigOutAdvFile1 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile1!==undefined && kioskUiSigOutAdvFile1!==null && kioskUiSigOutAdvFile1!=='' ? <img alt="img" src={kioskUiSigOutAdvFile1} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 2:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile2"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile2','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile2',kioskUiSigOutAdvFile2 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile2!==undefined && kioskUiSigOutAdvFile2!==null && kioskUiSigOutAdvFile2!=='' ? <img alt="img" src={kioskUiSigOutAdvFile2} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 3:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile3"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile3','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile3',kioskUiSigOutAdvFile3 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile3!==undefined && kioskUiSigOutAdvFile3!==null && kioskUiSigOutAdvFile3!=='' ? <img alt="img" src={kioskUiSigOutAdvFile3} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 4:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile4"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile4','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile4',kioskUiSigOutAdvFile4 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile4!==undefined && kioskUiSigOutAdvFile4!==null && kioskUiSigOutAdvFile4!=='' ? <img alt="img" src={kioskUiSigOutAdvFile4} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 5:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile5"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile5','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile5',kioskUiSigOutAdvFile5 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile5!==undefined && kioskUiSigOutAdvFile5!==null && kioskUiSigOutAdvFile5!=='' ? <img alt="img" src={kioskUiSigOutAdvFile5} width={100} height={100}/>:''}
                    </span>
            </div>
        </div>
        <div className="w-50">
        <div className="row pb-40"><span className="col-md-2">File 6:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile6"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile6','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile6',kioskUiSigOutAdvFile6 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile6!==undefined && kioskUiSigOutAdvFile6!==null && kioskUiSigOutAdvFile6!=='' ? <img alt="img" src={kioskUiSigOutAdvFile6} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 7:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile7"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile7','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile7',kioskUiSigOutAdvFile7 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile7!==undefined && kioskUiSigOutAdvFile7!==null && kioskUiSigOutAdvFile7!=='' ? <img alt="img" src={kioskUiSigOutAdvFile7} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 8:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile8"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile8','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile8',kioskUiSigOutAdvFile8 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile8!==undefined && kioskUiSigOutAdvFile8!==null && kioskUiSigOutAdvFile8!=='' ? <img alt="img" src={kioskUiSigOutAdvFile8} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 9:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile9"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile9','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile9',kioskUiSigOutAdvFile9 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile9!==undefined && kioskUiSigOutAdvFile9!==null && kioskUiSigOutAdvFile9!=='' ? <img alt="img" src={kioskUiSigOutAdvFile9} width={100} height={100}/>:''}
                    </span>
            </div>
            <div className="row pb-40"><span className="col-md-2">File 10:</span>   
            <span className="col-md-10"><InputGroup
                        type="file"
                        disabled={Disabled}
                        large={false}
                        placeholder="kioskUiSigOutAdvFile10"
                        readOnly={false}
                        onChange={(f:any)=>FileUploader('kioskUiSigOutAdvFile10','kiosk-inside-advertising',f.target.files) }
                        rightElement={<Button small={true} intent="primary"
                        onClick={(e:any)=>handleSiteChangesAndUpdate(true,'kioskUiSigOutAdvFile10',kioskUiSigOutAdvFile10 )} 
                        >change</Button>}
                    />
                     {kioskUiSigOutAdvFile10!==undefined && kioskUiSigOutAdvFile10!==null && kioskUiSigOutAdvFile10!=='' ? <img alt="img" src={kioskUiSigOutAdvFile10} width={100} height={100}/>:''}
                    </span>
            </div>
         </div>
        </div>
    </div>
    </div> :  ''}
        </div>
      
    )
}