import * as React from "react";
import '../../assets/css/points.css'
import { H3, H4 } from "@blueprintjs/core";


class BusinessList extends React.Component<any,any> {

public     componentDidMount(): void {
        console.log('list');
        
    }
    // 
public render() {
     const PointBusienssIds = this.props.businesses.PointBusinessIds;
     const businessnamelen=(n:any)=>{
        
            return n
        
     }

const activex:any =[]
const inactivex:any =[]
const notconfiguredx:any =[]


this.props.businesses.business.map((row:any,i:any)=>{
       
   if(!PointBusienssIds.includes(row.texn_business_uid)){
    notconfiguredx.push(row)
   } 
 
   
if(row.is_disabled===0 || row.is_disabled==='0' || row.is_disabled===null){
    activex.push(row)
 }else if(PointBusienssIds.includes(row.texn_business_uid)){  {
    inactivex.push(row) 
 }
   }
      

 })

const active = activex.sort((a:any, b:any) =>{ try { return (a.name.match(/\d+/g)  - b.name.match(/\d+/g) ) } catch (error) {  console.log(error);} });

const notconfigured =notconfiguredx.sort((a:any, b:any) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

const inactive  =inactivex.sort((a:any, b:any) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    return (
        <div className="BusiessListCss" >
         <H3 style={{textAlign:'center'}}>Business List</H3>
         <div>

         {active.length>0 ?  <H4 style={{textAlign:'left'}}> Active: </H4>:''}
       {active.length>0 ? active.map((row:any,i:any)=>(
        row.is_disabled===0 || row.is_disabled==='0' || row.is_disabled===null? 

       <label key={i} className="label "  onClick={()=>this.props.businessChange(row.business_uid)}><p className="listtab" title={row.name===undefined ? row.business_name : row.name}>&#x2637;&nbsp;{row.name===undefined ? businessnamelen(row.business_name) : businessnamelen(row.name) }

       <span style={{float:'right'}}>
       {PointBusienssIds.includes(row.texn_business_uid) ? '' : 
         <img className="m-1" src={require("../../assets/images/sign-add-icon (1).png")}  width={'24px'} title="New Business "/>
       }
    {row.is_disabled===0 || row.is_disabled==='0' || row.is_disabled===null ? 
       <img className="m-1" src={require("../../assets/images/th.jpg")}  width={'20px'} title="Active"/>
       : PointBusienssIds.includes(row.texn_business_uid) ? 
       <img className="m-1" src={require("../../assets/images/344-3448055_locked-blocked-icon.png")}  width={'20px'} title="Disabled"/> :''
       }
          
       </span>
       </p></label>
      :''  ))
    :
    <div >
        <img width={'50%'} style={{display:'block',margin:'auto'}} src={require("../../assets/images/loading_inline_dots.gif")}/>
    </div>
    }


<br/>

{inactive.length>0 ? <H4 style={{textAlign:'left'}}> Configured (not active): </H4>:''}
{inactive.length>0 ? inactive.map((row:any,i:any)=>(
    row.is_disabled===1 || row.is_disabled==='1' ?
       <label key={i} className="label "  onClick={()=>this.props.businessChange(row.business_uid)}><p className="listtab" title={row.name===undefined ? row.business_name : row.name}>&#x2637;&nbsp;{row.name===undefined ? businessnamelen(row.business_name) : businessnamelen(row.name) }

       <span style={{float:'right'}}>
       {PointBusienssIds.includes(row.texn_business_uid) ? '' : 
         <img className="m-1" src={require("../../assets/images/sign-add-icon (1).png")}  width={'24px'} title="New Business "/>
       }
    {row.is_disabled===0 || row.is_disabled==='0' || row.is_disabled===null ? 
       <img className="m-1" src={require("../../assets/images/th.jpg")}  width={'20px'} title="Active"/>
       : PointBusienssIds.includes(row.texn_business_uid) ? 
       <img className="m-1" src={require("../../assets/images/344-3448055_locked-blocked-icon.png")}  width={'20px'} title="Disabled"/> :''
       }
          
       </span>
       </p></label>
       :'' ))
    :''
    }
<br/>

{notconfigured.length>0 ? <H4 style={{textAlign:'left'}}>Not configured or active: </H4>:''}
{notconfigured.length>0 ? notconfigured.map((row:any,i:any)=>(
    !PointBusienssIds.includes(row.texn_business_uid)?
       <label key={i} className="label "  onClick={()=>this.props.businessChange(row.business_uid)}><p className="listtab" title={row.name===undefined ? row.business_name : row.name}>&#x2637;&nbsp;{row.name===undefined ? businessnamelen(row.business_name) : businessnamelen(row.name) }

       <span style={{float:'right'}}>
       {PointBusienssIds.includes(row.texn_business_uid) ? '' : 
         <img className="m-1" src={require("../../assets/images/sign-add-icon (1).png")}  width={'24px'} title="New Business "/>
       }
    {row.is_disabled===0 || row.is_disabled==='0' || row.is_disabled===null ? 
       <img className="m-1" src={require("../../assets/images/th.jpg")}  width={'20px'} title="Active"/>
       : PointBusienssIds.includes(row.texn_business_uid) ? 
       <img className="m-1" src={require("../../assets/images/344-3448055_locked-blocked-icon.png")}  width={'20px'} title="Disabled"/> :''
       }
          
       </span>
       </p></label>
       :'' ))
    :''
    }
        </div>
        </div>
    )
}

    
}

export default BusinessList




