
import { Button, Classes, Dialog, H1, H3, H4, HTMLTable, Icon, InputGroup, Label, Spinner } from "@blueprintjs/core";
import * as React from "react";
import { urls } from "../../global/firebase";
import '../../assets/css/style.css'
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { range } from "lodash";
let page= 0
export  class LocationCouponData extends React.Component<any,any>{

public state:any={
    coupons:[],
    yardapikey:'',
    couponsfinal:[],
    valu:[],
    inActiveCoupons:[],
    consumer_phone_number:'0000000000',
    nextcoupon:[],
    spendamt:[],
    spinner :'load',
    detail:[],
    modal:false,
    discounttotal:0,
    tooltipopen:false,
    tooltiptext:'',
    name:'',
    mobile:'',firstname:'',lastname:'',datex:'',datexx:'',
    nomoreresult:false

}


public  componentDidMount(): void {
    console.log(this.props,'kkkoo');
    this.setState({spinner:'load',nomoreresult:false})
    if(this.props.keyd!==this.state.yardapikey){
    page=0
    }
    page++
   this.getBussinessData(page)
}


public getBussinessData(page:any){


    const yardapikey = this.props.keyd; // is the string "Jonathan Smith".
    const val = this.props.conc; // is the string "Jonathan Smith".
    this.setState({valu:val.split(',')})
    this.setState({yardapikey})
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("yard_api_key", yardapikey);
    urlencoded.append("page", page);
    urlencoded.append("consumer_phone_number", this.state.mobile);
    urlencoded.append("first_name", this.state.firstname);
    urlencoded.append("last_name", this.state.lastname);
    urlencoded.append("start", this.state.datex);
    urlencoded.append("end", this.state.datexx);
    
    const requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(urls.pointsystem+'p1s5e4_get_business_coupon_data', requestOptions)
      .then(response => response.json())
      .then(result => {
        const arr:any = this.state.coupons
        if(result['errors'].length>0){
            if(result['errors'][0]['error_type_code']==6){
                alert('No more result')
                this.setState({nomoreresult:true})
            }else{
            // alert('no result found');
            this.setState({spinner:'noresult'})  
            }
        }   
        Object.keys(result).map((x:any)=>{
            let discount = 0
            if(x!=='errors'){
                result[x].coupons.map((j:any)=>{
                    if(j.coupon_used==1){
                        discount+=j.transaction_amount
                    }
                })
                result[x].totaldiscount =discount
                arr.push(result[x])                
            }
                
        })
         this.setState({spinner:'false',coupons:arr,couponsfinal:arr})   
                 
        })
      .catch(error => console.log('error', error));
}

public render(){
    const styleTh=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'center'as const,
        fontWeight:'600',
        borderRadius:'3px'

    }

    const styleTh1=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'center'as const,
        fontWeight:'800',
        color:'#388e3c',
        borderRadius:'3px'
        
    }
    const styleTh2=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        fontWeight:'800',
        textAlign:'center'as const,
        color:'#d32f2f',
        borderRadius:'3px'
        
    }
 
    
    const styleThX=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'left'as const,
        borderRadius:'3px'

    }

    const styleThY=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'right'as const,
        color:'#424242',
         fontWeight:'600',
        borderRadius:'3px'
    }

    const stylespan=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'22px',
        cursor:'pointer',
        color:'#fff'

    }
    const stylespan2=  {
        padding: '4px',
        fontWeight:600,
        color:'#3a3a3a',    
        cursor:'pointer'
    }

const Search=(v:any)=>{
    const arre:any=[]; 

    
 Object.keys(this.state.couponsfinal).sort().filter((x:any)=>{
if(x.includes(v)){
    arre[x] =this.state.couponsfinal[x]
 return true
}else{
    return false
}

    })
    this.setState({coupons:arre})
}
const Datetimeformate=(date:any)=>{
    
    const da = new Date(date);
    const day = da.getUTCDate()<10 ? '0'+da.getUTCDate():da.getUTCDate()
    const month = (da.getUTCMonth()+1)<10 ? '0'+(da.getUTCMonth()+1):(da.getUTCMonth()+1)
    const year = da.getUTCFullYear()
    console.log(`${month}/${day}/${year}`);
    
    return ` ${month}/${day}/${year} `
    }


    
    const AmountFormate=(x:any)=>{
           
        const options = { 
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          };
            const formatted =  Math.round(Number(x)).toLocaleString('en', options);
            // console.log(formatted);
        return formatted
    }

    const prettyMobile = (mobile: string) => {
        const areaCode = mobile.substring(0, 3);
        const prefix = mobile.substring(3, 6);
        const suffix = mobile.substring(6, 10);
    //   console.log(mobile);
      
        return `(${areaCode}) ${prefix}-${suffix}`;
      };

const csvDownload=()=>{
    try {
 
    const coupons = this.state.couponsfinal;
    const final:any =[]

    Object.keys(coupons).sort().map((x:any)=>{
        const arr:any = []
        let namE=''
        if(coupons[x].customer.first_name && coupons[x].customer.last_name){
            namE ='Name: '+coupons[x].customer.first_name+' '+coupons[x].customer.last_name
        }
        arr.push(['Mobile:  '+prettyMobile(coupons[x].customer.phone_number),namE,' ',' ',' ',' '])
        arr.push(['Active Coupons',' ',' ',' ',' ',' '])
        arr.push(['Coupon Deduction','Coupon Issue Date','Coupon Expire Date',' ',' ',' '])
        let j =0
        if(coupons[x].coupons!==undefined && coupons[x].coupons.length>0){        
            coupons[x].coupons.map((r:any)=>{
                    if(r.coupon_used==0){
                        j++
                arr.push([valuupdate(r.coupon_percentage+'%'),Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date),' ',' ',' ']); 
                    }                      
            })
        }
        if(j<=0){
            arr.push(['','No active coupons at this time',' ',' ',' ',' ']); 
        }
       
        
        if(coupons[x]!==undefined  && coupons[x].next_coupon!==undefined){

        console.log(arr.length);
if(arr.length<=11){
    range(11-arr.length).map(()=>{
        arr.push([' ',' ',' ',' ',' ','']);
    })
}
            arr[1][4] = 'Customer Point & Next Coupon Overview'
            arr[2][4] = 'Coupon Percentage'
            arr[3][4] = 'Current Point Balance'
            arr[4][4] = 'Points Need To Next Coupon'
            arr[5][4] = 'Points Need To Next Coupon'
            arr[5][4] = ''
            arr[6][4] = 'Customer Spend & Discount Overview'
            arr[7][4] = 'Last Month Spend'
            arr[8][4] = 'Last 3 Months Spend'
            arr[9][4] = 'Last 6 Months Spend'
            arr[10][4] = 'Last Year Spend'



            arr[2][5] = valuupdate(coupons[x].next_coupon.coupon_percentage+'%')
            arr[3][5] = Math.round(parseInt(coupons[x].next_coupon.progress_towards,10))+' Points'
            arr[4][5] = Math.round(parseInt(coupons[x].next_coupon.progress_left,10))+' Points'

   if(coupons[x]!==undefined  && coupons[x].spent_amounts!==undefined ){
    arr[7][5] = '$'+Math.round(coupons[x].spent_amounts.last_month.toFixed(2))
    arr[8][5] ='$'+Math.round(coupons[x].spent_amounts.last_3_months.toFixed(2))
    arr[9][5] = '$'+Math.round(coupons[x].spent_amounts.last_6_months.toFixed(2))
    arr[10][5] = '$'+Math.round(coupons[x].spent_amounts.last_year.toFixed(2))
   }

            
   }   
   arr.push(['','',' ',' ',' ',' ']); 



   arr.push(['Redeemed Coupons',' ',' ',' ',' ',' '])
   arr.push(['Date/Time Coupon Redeemed','Points Before Coupon','Coupon Deduction','Points After Coupon','Customer Discount','SubTotal','Coupon Issue Date','Coupon Expire Date'])
   let i =0
   if(coupons[x].coupons.length>0){        
    coupons[x].coupons.map((r:any)=>{
    if(r.coupon_used==1){
     i++
     const subtotal = r.p1s5e3_subtotal!==null ?  '$'+r.p1s5e3_subtotal : 'N/A'
        arr.push([useddate(r),(r.points_before_coupon!==null ? r.points_before_coupon: '0')+' Points',valuupdate(r.coupon_percentage+'%'),(r.points_after_coupon!==null ? r.points_after_coupon : '0')+' Points','$'+r.transaction_amount,subtotal,Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date)]);
    }
   });

   }
   if(i<=0){
       arr.push(['','No redeemed coupons at this time',' ',' ',' ',' ']); 
   } 
   const devider =':::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
   arr.push(['','','','','','']);
//    arr.push([devider,devider,devider,devider,devider,devider]);
   arr.push(['','','','','','']);
   final.push(arr)
})

var data = final;
const finalarr:any = [];
data.map((x:any)=>{
    x.map((nx:any)=>{
        finalarr.push(nx)
    })

})


const seperator = ",";


const csv = [
    ...finalarr.map((row:any )=>  [0,1,2,3,4,5].map(field => `${row[field]}`).join(seperator))
]

//Export our csv in rows to a csv file
let csvContent = "data:text/csv;charset=utf-8," 
    + csv.join("\n");
var encodedUri = encodeURI(csvContent);
const url = encodedUri;
const link = document.createElement('a');
link.href = url;
link.download = 'filename.csv';
link.click();
URL.revokeObjectURL(url);

// window.open(encodedUri);
       
} catch (error) {
        console.log(error);
        
}
}

const useddate=(r:any)=>{
    const time =  r.time_used!==undefined ? timeConvertFormat(r.time_used) :'' ;
    const dat =r.date_used!==undefined ? Datetimeformate(r.date_used) :'';
    return  `${dat} ${time} CST`
    }
    
const valuupdate=(ind:any)=>{    
    let wr = '';
    if(this.state.valu.length>0){
        this.state.valu.map((v:any)=>{
if(v.includes(ind)){
    wr=v
}

        })
    }

    return wr;
}

const timeConvertFormat = (time:any)=>{

    const timeString = time
    // Prepend any date. Use your birthday.
    const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
      .toLocaleTimeString('en-US',
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
      );
      return timeString12hr;

}

const openmodal=(detail:any)=>{
    try {
    
    this.setState({detail:JSON.parse(detail),modal:true})
        
    } catch (error) {
        console.log(error);
        
    }
    }

const clearfilter=()=>{
  
    this.setState({mobile:'',firstname:'',lastname:'',datex:'',datexx:'',coupons:[],couponsfinal:[],spinner:'load',nomoreresult:false})
setTimeout(() => {
    this.getBussinessData(1)
}, 1000);
}

const quickSearch=()=>{
    this.setState({coupons:[],couponsfinal:[],spinner:'load',nomoreresult:false})
    page = 1
    this.getBussinessData(1)
    // const  mobile = this.state.mobile;
    // const  firstname = ((this.state.firstname).toString()).toLowerCase();
    // const  lastname = ((this.state.lastname).toString()).toLowerCase()
    // let  datex = this.state.datex;
    // let  datexx = this.state.datexx;
    // if(datex!==''){
    //     datex= new Date(datex).toLocaleDateString()
    // }
    // if(datexx!==''){
    //     datexx= new Date(datexx).toLocaleDateString()
    // }
 
    // const final1 =this.state.coupons.filter((re:any)=>{
    //     if(
    //         (mobile=='' || re.customer.phone_number.includes(mobile)) &&
    //         (firstname=='' || (re.customer.first_name.toString()).toLowerCase().includes(firstname)) &&
    //         (lastname=='' || (re.customer.last_name.toString()).toLowerCase().includes(lastname))
    //     ){
    //         return true
    //     }else{
    //         return false
    //     }
        
    //     })
    //     console.log('final',final1);
    // const final2 =  final1.filter((rex:any)=>{

    //     const couponv:any = []

      
        
        
  
    //     let discount =0

    //      Object.keys(rex.coupons).map((r:any,i:any)=>{       

    //         console.log(datex,datexx, (new Date(rex.coupons[r].issue_date).toLocaleDateString())>=datex , (new Date(rex.coupons[r].issue_date).toLocaleDateString())<=datexx);
                    
    //             if(datex=='' || datexx=='' ){

    //                 if(rex.coupons[r].coupon_used==1){
    //                     discount+=rex.coupons[r].transaction_amount
    //                 }

    //                 couponv.push(rex.coupons[r])                   
    //             }else if(datex<=(new Date(rex.coupons[r].issue_date).toLocaleDateString()) &&  datexx>=(new Date(rex.coupons[r].issue_date).toLocaleDateString())){   
                    
    //                 if(rex.coupons[r].coupon_used==1){
    //                     discount+=rex.coupons[r].transaction_amount
    //                 }
                    
    //                 couponv.push(rex.coupons[r])
                   
    //             }
    //         }) 

    //     if(couponv.length>0){  
    //     rex.totaldiscount =discount   
    //     rex.coupons=couponv 
               
    //     return rex 
    //     } else{
    //         return false
    //     }   
        
    //     })
    // console.log('final2',final2);

    // this.setState({couponsfinal:final2})
}


const handleScroll = (e:any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
    );

    let timer;
    if(position==100){
        if((this.state.datex=='' && this.state.datexx=='')){
            if(!this.state.nomoreresult){
        e.target.scrollTop=e.target.scrollTop-300
        clearTimeout(timer);
        this.setState({spinner:'paginationload'})
        timer = setTimeout(() => {
            console.log(position);
            page += 1          
            this.getBussinessData(page)
        }, 300);
    }
      }
 
    }
};

    return(
        <div  style={{fontSize:'17px',textAlign:'left',background:'#fff' ,height:window.innerHeight-300,overflow:this.state.spinner=='paginationload' ? 'hidden':'auto'}} onScroll={(e)=>handleScroll(e)}>

{this.state.spinner=='paginationload'? <div style={{
        textAlign: 'center',
        position: 'absolute',
        zIndex: '1000',
        left: '40%',
        top: '50%',
}}>
<img height={100} width={100} src="https://i.gifer.com/ZZ5H.gif"/>
</div>:''}

<Dialog

title="Help"
icon="info-sign"
isOpen={this.state.tooltipopen}
style={{zIndex:'2000'}}
onClose={()=>this.setState({tooltipopen:false})}>
<div style={{padding:'10px',zIndex:'20000'}}>
{this.state.tooltiptext}
</div>
</Dialog>


<Dialog

                title="Coupon Trasaction Details"
                icon="info-sign"
                isOpen={this.state.modal}
                style={{width:'90%'}}                
                onClose={()=>this.setState({modal:false})}>
                <div className={Classes.DIALOG_BODY} style={{overflow:'auto'}}>

                    {this.state.detail!==null && this.state.detail[0]!==undefined && this.state.detail.length>0?<HTMLTable border={1}>
                    <tr>
                            <th>Detail Number <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'An internal reference number in Crush for further transaction details.'})}/></th>
                            <th>Transaction ID <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'This is the reference number in Crush for this specific transaction.'})}/></th>
                            <th>Discount Rate (%) <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The discount rate applied from the coupon. Calculated as a percentage of the Part Total.'})}/></th>
                            <th>Discount Type <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The specific type of coupon applied in this transaction.'})}/></th>
                            <th>Part Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The total price of all parts in the transaction before applying any discounts.'})}/></th>
                            <th>Discount Amount <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Total dollar amount saved based on the Part Total and Discount Rate.'})}/></th>
                            <th>Pricing Standard <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Pricing standard used for this order, typically set as'})}/></th>
                            <th>Part Name <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The specific type of part(s) associated with this transaction.'})}/></th>
                            <th>Part Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The total quantity of parts included in this transaction.'})}/></th>
                            <th>Core Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The number of cores (recyclable parts) included in this order.'})}/></th>
                            <th>Core Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The core charge amount for this transaction, representing the cost of recyclable parts.'})}/></th>
                            <th>Warranty Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The amount spent on warranties for this order, which may be $0 if no warranty was purchased.'})}/></th>
                           
                           
                        </tr>
                     { this.state.detail.map((x:any)=>(  <tr>
                        
                        <td>{x.DetailNo}</td>
                        <td>{x.TransRno}</td>
                        <td>{x.DiscountRate}</td>
                        <td>{x.DiscountName}</td>
                        <td>${Number(x.PartTotal).toFixed(2)}</td>
                        <td>${Number(x.DiscountAmount).toFixed(2)}</td> 
                        <td>{x.PriceList}</td>
                        <td>{x.PartName}</td>
                        <td>{x.PartQty}</td>                        
                        <td>{x.CoreQty}</td>                        
                        <td>${Number(x.CoreTotal).toFixed(2)}</td>
                        <td>${Number(x.WarrantyTotal).toFixed(2)}</td>
                                        
                        </tr>))}
                      
         

                    </HTMLTable>:''}
          </div>
          </Dialog>

            {/* <H1>Yard Api Key: {this.state.yardapikey}</H1> */}
            {/* <div style={{position:'fixed',padding:'5px',border:'1px solid #000',top:'0px',background:'#90caf9',color:'#fff',borderRadius:'5px'}}>                
            Download Csv:&nbsp; 

            </div> */}
      
            <Label style={{position:'sticky',top:'-25px',padding:'5px',background:'#fff'}}>Search Customer Points & Coupons<br/>
            <div style={{display:'ruby'}}>
            <InputGroup disabled={this.state.spinner=='load'} type={'number'}  max={12} onChange={(e:any)=>{this.setState({mobile:e.target.value})}} placeholder='Enter mobile number...'  value={this.state.mobile}  />&nbsp;
            <InputGroup disabled={this.state.spinner=='load'} type={'text'}  max={12} onChange={(e:any)=>{this.setState({firstname:e.target.value})}} placeholder='Enter first name...' value={this.state.firstname}  />&nbsp;
            <InputGroup disabled={this.state.spinner=='load'} type={'text'}  max={12} onChange={(e:any)=>{this.setState({lastname:e.target.value})}} placeholder='Enter last name...' value={this.state.lastname}  />&nbsp;
            <InputGroup  disabled={this.state.spinner=='load'} type={'date'} defaultValue={this.state.datex} leftElement={(<Button  disabled={this.state.spinner=='load'}   style={{cursor:'auto'}}>Start date</Button>)} onChange={(e:any)=>{this.setState({datex:e.target.value}); this.setState({datexx:e.target.value})}}  value={this.state.datex}  />&nbsp;

            <InputGroup disabled={this.state.spinner=='load'} type={'date'} defaultValue={this.state.datexx} leftElement={(<Button  disabled={this.state.spinner=='load'}   style={{cursor:'auto'}}>End date</Button>)} onChange={(e:any)=>{this.setState({datexx:e.target.value})}}  value={this.state.datexx}  />&nbsp;
            <Button disabled={this.state.spinner=='load'} intent={'primary'}  onClick={()=>quickSearch()}  rightIcon={'search'}>Search</Button>&nbsp;
            <Button disabled={this.state.spinner=='load'} intent={'none'}    onClick={()=>clearfilter()}>clear filter</Button>

            <Button disabled={this.state.spinner=='load'} style={{position:'absolute',right:'5px'}} intent="success" onClick={()=>csvDownload()} title="download csv" rightIcon='download'> Download Csv </Button>
            </div>
            </Label>

            <p>{this.state.mobile!=='' || this.state.firstname!=='' ||this.state.lastname!=='' ||this.state.datex!=='' ? 'Results for ' :''} {this.state.mobile!=='' ? 'mobile '+this.state.mobile  :''}  {this.state.firstname!=='' || this.state.lastname!==''   ? this.state.firstname+' '+this.state.lastname  :''} {this.state.datex!=='' ? 'Purchases Between '+Datetimeformate(this.state.datex)+' and '+Datetimeformate(this.state.datexx)  :''}</p>
  
    { this.state.couponsfinal.length>0 ? this.state.couponsfinal.map((val:any)=>(
        <div className="row-full-width" style={{background:'#dfdfdf',padding:'6px',borderBottom:'2px solid #fff'}}>
          <div className="w-71">
          <H3 style={{color:'#424242'}}>Name: {  val.customer!==undefined  ? val.customer.first_name:''} {  val.customer!==undefined  ? val.customer.last_name:''}</H3>
          <H3 style={{color:'#424242'}}>Customer Phone Number: {  val.customer!==undefined  ? prettyMobile(val.customer.phone_number):''}</H3>
            <div style={{margin:'10px',background:'#fff'}}>    
              <div>
              <div style={{background: 'linear-gradient(17deg, #ededed, #00000003)'}}>
                    <span style={stylespan2}> <H4 style={{color:'#3a3a3a',padding:'3px',background:'linear-gradient(171deg, #eeeeee, transparent)'}}>&nbsp;Active Coupons <Icon  icon='info-sign' onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Active Coupons section displays all coupons the customer qualifies for based on their current points balance. The highest-value coupon, shown at the top, is the redeemable coupon available at checkout. This coupon is updated each evening to reflect purchases and redemptions. If a purchase moves the customer into a new tier, the highest coupon will update the next day, replacing the previous one. Lower-tier coupons are also listed here but are not redeemable. Redeemed coupons appear in the Redeemed Coupons table. The Coupon Issue Date shows when each tier was reached, and the Coupon Expire Date follows preset rules.'})}  /></H4></span>
                        <p  style={{overflow:'auto',paddingBottom:'5px'}}>    
                        <HTMLTable  >   
                            <thead>                  
                                <tr>                                
                                 
                                    <th style={styleTh1}>Coupon Deduction</th>
                                    <th style={styleTh1}>Coupon Issue Date</th>
                                    <th style={styleTh1}>Coupon Expire Date</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                            { Object.keys(val.coupons).length>0 ?  Object.keys(val.coupons).map((r:any,i:any)=>(
                        val.coupons[r].coupon_used==0?        
                                <tr >                                 
                                    <td style={i==0  ? {background:'#e0f7fa',boxShadow:'0px 0px 9px 3px rgb(52 135 56 / 50%)',border:'1px solid #388e3c',padding: '10px',borderRadius:'3px',textAlign:'center'} :styleTh}>
                                        <H4>{valuupdate(val.coupons[r].coupon_percentage+'%')} <sup><small><em>{(i==0 ? ' (Primary)':'')}</em></small></sup></H4></td>
                                    <td style={i==0  ? {background:'#e0f7fa',border:'1px solid #388e3c',boxShadow:'0px 0px 9px 3px rgb(52 135 56 / 50%)',padding: '10px',borderRadius:'3px',textAlign:'center'} :styleTh}><H4>{Datetimeformate(val.coupons[r].issue_date)}</H4></td>
                                    <td style={i==0  ? {background:'#e0f7fa',border:'1px solid #388e3c',boxShadow:'0px 0px 9px 3px rgb(52 135 56 / 50%)',padding: '10px',borderRadius:'3px',textAlign:'center'} :styleTh}><H4>{Datetimeformate(val.coupons[r].expiry_date)}</H4></td>
                              
                                </tr>
                                    :''
                            )) :  <tr><td colSpan={3} style={{color:'#757575'}}><em>No active coupons at this time</em></td></tr>  } 
                            </tbody>
                            </HTMLTable>                       
                        </p>
                        </div>    

                        {/* INACTIVE COUPONS */}
                        <div style={{background: 'linear-gradient(124deg, #f2f2f2, #00000000)'}}>
                       <span style={stylespan2}> <H4 style={{color:'#3a3a3a',padding:'3px',background:'linear-gradient(171deg, #eeeeee, transparent)'}}>&nbsp;Redeemed Coupons <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Redeemed Coupons are displayed in real-time when a coupon is redeemed through Crush. However, some fields, like Points After Coupon and SubTotal, are updated in the evening after Crush sends this data. Until then, these fields will show as N/A. Please check back the following day for the updated information.'})} /></H4></span>
                        <p  style={{overflow:'auto'}}>    
                            <HTMLTable >  
                                <thead>         
                                <tr>   
                                <th style={styleTh2}>Date/Time Coupon Redeemed </th>                             
                                   <th style={styleTh2}>Points Before Coupon </th>
                                    <th style={styleTh2}>Coupon Deduction </th>                                    
                                    <th style={styleTh2}>Points After Coupon <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Points After Coupon will display N/A until the day after a coupon is applied. This is because it’s not just Points Before Coupon minus Coupon Deduction. The SubTotal, which updates nightly, also factors into the calculation'})}/></th> 
                                    <th style={styleTh2}>Customer Discount </th>
                                    <th style={styleTh2}>SubTotal <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Points After Coupon should also display N/A until the SubTotal is updated, as its not simply a matter of subtracting the coupon deduction from the Points Before Coupon. The SubTotal amount needs to be factored in because points are earned based on the final SubTotal, after the coupon deduction is applied, in order to accurately calculate the Points After Coupon.'})}/></th>

                                    <th style={styleTh2}>Coupon Issue Date </th>
                                    <th style={styleTh2}>Coupon Expire Date </th>                                    
                                    <th style={styleTh2}>Detail <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Details section provides in-depth transaction information for redeemed coupons. If details are not yet available, it may be because the coupon was redeemed earlier today, and the data updates overnight. Check back the following day for full details. Once populated, this section includes: Transaction ID (reference number in Crush), Detail Number (additional reference), Discount Rate (percentage off), Discount Amount (amount saved), Part Total (cost of parts before discount), Part Name (type of part), Part Quantity, Warranty Total (if any), Core Quantity, and Core Total.'})}/></th>
                                </tr>
                                </thead>  
                                
                                <tbody>   
                            {Object.keys(val.coupons).length>0 ? Object.keys(val.coupons).map((rx:any)=>(
                         val.coupons[rx].coupon_used==1?
                                <tr>
                                    <td style={styleTh}>{val.coupons[rx].date_used!==undefined ? Datetimeformate(val.coupons[rx].date_used) :''}  {val.coupons[rx].time_used!==undefined ? timeConvertFormat(val.coupons[rx].time_used) :''} CST</td>
                                    <td style={styleTh}>{AmountFormate(val.coupons[rx].points_before_coupon)+' Points'}</td>
                                    <td style={styleTh}>{valuupdate(val.coupons[rx].coupon_percentage+'%')}</td>
                                    <td style={styleTh}>{AmountFormate((parseInt(val.coupons[rx].points_after_coupon)>0 ? parseInt(val.coupons[rx].points_after_coupon) :0)+(parseInt(val.coupons[rx].p1s5e3_subtotal,10)>0 ? parseInt(val.coupons[rx].p1s5e3_subtotal,10) :0))+' Points'}</td>
                                    <td style={styleTh} title={rx.transaction_id!==undefined ? val.coupons[rx].transaction_id :''}>${val.coupons[rx].transaction_amount!==undefined ? val.coupons[rx].transaction_amount :''}</td>
                                    <td style={styleTh}>{parseInt(AmountFormate(val.coupons[rx].p1s5e3_subtotal),10)<=0  ? 'N/A' :'$'+AmountFormate(val.coupons[rx].p1s5e3_subtotal) }</td>

                                    <td style={styleTh}>{Datetimeformate(val.coupons[rx].issue_date)}</td>
                                    <td style={styleTh}>{Datetimeformate(val.coupons[rx].expiry_date)}</td>
                                    <td style={styleTh} onClick={()=>openmodal(val.coupons[rx].p1s5e3_transaction_details)}>{val.coupons[rx].p1s5e3_transaction_details!==null ? <Icon icon='eye-open'/>:'N/A'}</td>

                                </tr> :'' 
                                                                 
                            )) :  <tr><td colSpan={3} style={{color:'#757575'}}><em>No redeemed coupons at this time</em></td></tr>}  

                            <tr>
                            <td style={styleTh} colSpan={3}></td>
                            <td style={styleTh}><b>Total Customer Discounts</b></td>
                            <td style={styleTh}><b>${ AmountFormate(val.totaldiscount )}</b></td>
                            <td style={styleTh}></td>
                            <td style={styleTh}></td><td style={styleTh}></td>
                            <td style={styleTh}></td>
                            </tr>    
                               </tbody>     
                               </HTMLTable>                  
                        </p>
                        </div>                        
                        </div>            
                
                
                        </div>

             </div>
            <div className="w-31">
                       <div style={{margin:'10px',background:'#e3f2fd',padding:'4px'}}>
                        <H4>Customer Point & Next Coupon Overview <Icon style={{color:'#5f6368',cursor:'pointer'}}  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Next Coupon shows the value of the reward you are currently working towards.'})}/></H4>
                        {val.next_coupon!==undefined && val.next_coupon.coupon_cap_amount!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                              <HTMLTable>                          
                                
                                 <tr>
                                <th style={styleThX}><H4>Current Point Balance <Icon style={{color:'#5f6368',cursor:'pointer'}}  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Current Points Balance shows the total points you’ve accumulated for purchases, including those where a coupon was applied. When you redeem a coupon, the points deducted are subtracted from this balance, but points from the subtotal of that transaction are added back. The balance is updated at the end of each day, so the points shown reflect the previous days activity and may not account for any purchases or redemptions made today. Even after applying a coupon, any leftover points after the deduction remain in your balance. Its possible to redeem a coupon and earn enough points in the same day to qualify for a new coupon, which will be issued after the day’s transactions are processed'})} /></H4></th>
                                <td style={styleThY}><H4 style={{color:'#388e3c'}}>{AmountFormate(parseInt(val.next_coupon.progress_towards,10))} Points</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Points Need To Next Coupon</H4></th>
                                <td style={styleThY}><H4>{AmountFormate(parseInt(val.next_coupon.progress_left,10))} Points</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Next Coupon Value</H4></th>
                                <td style={styleThY}><H4>{val.next_coupon.coupon_percentage}%</H4></td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                        </div>  


                         <div  style={{margin:'10px',background:'#e3f2fd',padding:'4px'}}>
                        <H4>Customer Spend & Discount Overview <Icon  style={{color:'#5f6368',cursor:'pointer'}}  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Customer Spend & Discount Overview provides a breakdown of the customer’s spending over the last month, 3 months, 6 months, and the past year, along with the total amount saved through discounts. This overview gives insights into customer spending behavior and shows how much has been discounted over time.'})} /></H4>
                        {val.spent_amounts.last_3_months!==undefined    ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                                 <HTMLTable>
                              <tr>
                                <th style={styleThX}><H4>Last Month Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(val.spent_amounts.last_month.toFixed(2))}</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Last 3 Months Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(val.spent_amounts.last_3_months.toFixed(2))}</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Last 6 Months Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(val.spent_amounts.last_6_months.toFixed(2))}</H4></td>
                                </tr>                              
                                <tr>
                                <th style={styleThX}><H4>Last Year Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(val.spent_amounts.last_year.toFixed(2))}</H4></td>
                                </tr>
                                <tr>
                                <th style={{border:'2px solid #000000',padding:'4px'}}><H4><b>Total Customer Discounts</b></H4></th>
                                <td style={{border:'2px solid #000000',padding:'4px',borderLeft:'none',textAlign:'end'}}><H4>${AmountFormate(val.totaldiscount )}</H4></td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                    </div>                    
             
                </div>
                    </div>))  :  this.state.spinner=='load' ?  <Spinner intent={'primary'} size={30}/>: <p style={{color:'#757575'}}><em>No results found</em></p>}

                    


        </div>
    )
}


}


